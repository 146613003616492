@media (max-width: 768px){
    .Home{
        font-size: 16px;
        --max-allowed-width: 768px;
        width: 100%;
        height: auto;
        position: relative;
    }


    /*-------------------------------Styles for first 100vh --------------------------------*/

    .home_flex_row_1{
        width: 100%;
        display: flex;
        flex-direction: column;
        min-height: 25rem;;
    }

    .home_flex_row_1_infobox_1{
        width: 100%;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: var(--header-height) 2rem 0;
        box-sizing: border-box;
    }

    .home_flex_row_1_infobox_1 > h2{
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        line-height: 1;
        width: 100%;
    }

    .home_flex_row_1_infobox_1 > h2 > span{
        color: #024E85;
    }

    .home_flex_row_1_infobox_1 > p{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        margin: 2rem 0;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .home_flex_row_1_signup{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .home_flex_row_1_signup > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0 auto 1rem;
        border: none;
        outline: none;
        width: auto;
        height: auto;
        flex-grow: 1;
        transition: background-color 0.3s linear;
    }

    .home_flex_row_1_signup > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .home_flex_row_1_signup > input:focus{
        background-color: #E2E2E2;
    }

    .home_flex_row_1_signup > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        text-align: center;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        margin: 0 auto 2rem;
        cursor: pointer;
    }

    .home_flex_row_1_signup > .home_page_progress_container {
        width: 7rem;
        margin: 0 auto 2rem;
        height: 2.25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    /*-------------------------------Test series information styles ---------------------------*/

    .home_test_series_info{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }

    .home_test_series_info_textbox_desktop{
        display: none;
    }

    .home_test_series_info_textbox_mobile{
        width: 100%;
        box-sizing: border-box;
        padding: 0 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 4rem 0;
    }

    .home_test_series_info_textbox_mobile > h2{
        text-align: center;
        font-size: 2.5rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .home_test_series_info_textbox_mobile > p{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        margin: 1.5rem 0 0;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .home_test_series_info_item{
        min-width: 20rem;
        height: 20rem;
        min-height: 19rem;
        display: flex;
        flex-direction: column;
        padding: 2.5rem;
        box-sizing: border-box;
        position: relative;
        background-color: #F4F4F4;
        margin: 4rem 1rem;
    }

    .home_test_series_info_item > img{
        width: 2.5rem;
        height: 2.5rem;
        align-self: flex-start;
        object-position: center;
        object-fit: contain;
    }

    .home_test_series_info_item > p.home_test_series_info_item_title{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        width: 60%;
        text-align: start;
        margin: 1.5rem 0 0;
        font-size: 1.6rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        transition: all 0.1s ease;
    }

    .home_test_series_info_item > p.home_test_series_info_item_text{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        font-size: 1rem;
        /*opacity: 0;*/
        /*transform: scale(0);*/
        margin: auto 0 0;
        transition: opacity 0.1s ease, transform 0.1s ease;
    }

    .home_test_series_info_item > a.home_test_series_info_item_action-button{
        width: 2rem;
        height: 2rem;
        max-width: 2.25rem;
        max-height: 2.25rem;
        align-self: flex-end;
        border-radius: 1.25rem;
        background-image: url("../Assets/round-transparent-bg-arrow.png");
        background-color: white;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg);
        cursor: pointer;
        margin-top: auto;
    }

    /*!*--------------------------------------Why Eval section --------------------------------*!*/

    h1.home_why_eval_title_main{
        color: black;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        font-size: 4rem;
        margin: 4rem 0;
    }

    .home_why_eval_item-1{
        width: 100%;
        /*height: 50vw;*/
        /*min-height: 25rem;*/
        display: flex;
        flex-direction: column;
        /*max-width: var(--max-allowed-width);*/
        /*max-height: calc(var(--max-allowed-width)/2);*/
        margin: auto;
    }

    .home_why_eval_item-1_imagebox{
        background-color: #024E85;
        width: 100%;
        height: 30rem;
        overflow: hidden;
        position: relative;
        padding: 15%;
        box-sizing: border-box;
    }


    .home_why_eval_item-1_imagebox > svg.blue_circle{
        position: absolute;
        top: 0;
        left: 0;
    }

    .home_why_eval_item-1_imagebox > img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        top: 0;
        left: 0;
    }

    .home_why_eval_item-1_textbox{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10%;
        box-sizing: border-box;
        margin: 2rem 0 5rem;
    }

    .home_why_eval_item-1_textbox > h2 {
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .home_why_eval_item-1_textbox > p {
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        margin: 1.5rem 0;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        width: 100%;
    }

    .home_why_eval_item-1_textbox > a {
        font-family: Josefinsans, sans-serif;
        text-decoration: none;
        font-size: 0.75rem;
        color: black;
        padding: 0.75rem 2rem;
        border-radius: 0.75rem;
        background-color: #F4F5F7;
        outline: none;
        border: none;
        cursor: pointer;
    }


    /*!*Itrem 2*!*/
    .home_why_eval_item-2{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        margin: auto;
    }

    .home_why_eval_item-2_imagebox{
        position: relative;
        background-color: #BC4830;
        width: 100%;
        height: 30rem;
        overflow: hidden;
        padding: 15%;
        box-sizing: border-box;
    }

    .home_why_eval_item-2_imagebox > svg.red_circle{
        position: absolute;
        top: 0;
        left: 0;
    }

    .home_why_eval_item-2_imagebox > img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        top: 0;
        left: 0;
    }

    .home_why_eval_item-2_textbox{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10%;
        box-sizing: border-box;
        margin: 2rem 0 5rem;
    }

    .home_why_eval_item-2_textbox > h2 {
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .home_why_eval_item-2_textbox > p {
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        margin: 1.5rem 0;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        width: 100%;
    }

    .home_why_eval_item-2_textbox > a {
        font-family: Josefinsans, sans-serif;
        text-decoration: none;
        font-size: 0.75rem;
        color: black;
        padding: 0.75rem 2rem;
        border-radius: 0.75rem;
        background-color: #F4F5F7;
        outline: none;
        border: none;
        cursor: pointer;
    }

    /*!*------------------------------ Testimonials section -------------------------*!*/

    .home_testimonials{
        width: 100%;
        height: 28rem;
        display: flex;
        flex-direction: row;
        position: relative;
    }

    svg.home_testimonials_background{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    svg.home_testimonials_background > rect{
        width: 55%;
        height: 100%;
        z-index: 0;
    }

    svg.home_testimonials_background > ellipse{
        z-index: 0;
        cx: 60%;
        cy: 50%;
        rx: 12rem;
        ry: 11rem;
        fill: white;
    }

    .home_testimonials_buttons{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 10%;
        height: 100%;
        margin: 0 1rem;
    }

    .home_testimonials_buttons_button-up{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
        background: white url("../Assets/round-transparent-bg-arrow.png") no-repeat center;
        background-size: contain;
        margin-bottom: 1.5rem;
        cursor: pointer;
        outline: none;
        border: none;
        z-index: 1;
    }

    .home_testimonials_buttons_button-down{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
        background: white url("../Assets/round-transparent-bg-arrow.png") no-repeat center;
        background-size: contain;
        transform: rotate(180deg);
        cursor: pointer;
        outline: none;
        border: none;
    }

    .home_testimonials_content {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        justify-content: center;
        align-items: center;
        padding: 1rem 1rem 0 0;
        box-sizing: border-box;
    }

    .home_testimonials_content > h2{
        text-align: center;
        font-size: 2.5rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .home_testimonials_content_item_container{
        margin: 1.5rem 0 0;
        height: 80%;
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .home_testimonials_content_item{
        width: 100%;
        height: auto;
        position: absolute;
    }

    .home_testimonials_content_item > p.home_testimonials_content_item_text{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        margin: 0 0 1rem;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        line-height: 1.2;
        width: 100%;
    }

    .home_testimonials_content_item > p.home_testimonials_content_item_name {
        color: black;
        font-family: Gibson, sans-serif;
        text-align: center;
        margin: 1.5rem 0 0;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
    }

    .home_testimonials_content_item > p.home_testimonials_content_item_name > span{
        color: #687086;
    }

    h1.home_achievements_title_main, h1.home_trusted_by_title{
        color: black;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        font-size: 4rem;
        margin: 4rem 0;
    }

    /*!*----------------------Setup account section-----------------------*!*/

    .home_setup_account_section {
        height: 15rem;
        width: 100%;
        background: #F9FAFB;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .home_setup_account_section > h2{
        text-align: center;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        letter-spacing: 0.1rem;
        margin: 0;
        width: 25rem;
        /*text-decoration: underline;*/
    }

    .home_setup_account_section > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 2rem 1rem 0;
        border: none;
        outline: none;
        width: 15rem;
        height: auto;
        transition: background-color 0.3s linear;
    }

    .home_setup_account_section > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .home_setup_account_section > input:focus{
        background-color: #E2E2E2;
    }

    .home_setup_account_section > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        cursor: pointer;
        margin: 1rem 0 0;
    }

    .home_setup_account_section > .home_page_progress_container {
        width: 7rem;
        height: 2.25rem;
        margin: 1rem 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 769px){
    .Home{
        font-size: 16px;
        --min-allowed-width: 1000px;
        --max-allowed-width: 1280px;
        width: 100%;
        min-width: var(--min-allowed-width);
        height: auto;
        position: relative;
    }

    /*-------------------------Header styles -------------------*/

    /*.home_header{*/
    /*    width: 100%;*/
    /*    height: var(--header-height);*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    left: 0;*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*}*/

    /*.home_header_left-half{*/
    /*    width: 50%;*/
    /*    height: 100%;*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*    justify-content: space-evenly;*/
    /*    align-items: center;*/
    /*    padding: 0 2rem;*/
    /*    box-sizing: border-box;*/
    /*}*/

    /*.home_header_left-half > img{*/
    /*    height: 2rem;*/
    /*    cursor: pointer;*/
    /*}*/

    /*.home_header_left-half > a{*/
    /*    color: black;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    font-size: 0.9rem;*/
    /*    font-weight: bold;*/
    /*    text-decoration: none;*/
    /*    cursor: pointer;*/
    /*}*/

    /*.home_header_left-half > a:hover{*/
    /*    color: #024E85;*/
    /*}*/

    /*.home_header_right-half{*/
    /*    width: 50%;*/
    /*    height: 100%;*/
    /*    display: flex;*/
    /*    box-sizing: border-box;*/
    /*    flex-direction: row;*/
    /*    justify-content: flex-end;*/
    /*    align-items: center;*/
    /*    padding: 0 2rem;*/
    /*}*/

    /*.home_header_right-half > .home_header_right-half_link-text{*/
    /*    color: #363636;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    margin: 0 1rem;*/
    /*    font-size: 0.8rem;*/
    /*    font-weight: normal;*/
    /*    text-decoration: underline;*/
    /*    cursor: pointer;*/
    /*}*/

    /*.home_header_right-half > .home_header_right-half_link-button_transparent{*/
    /*    color: #363636;*/
    /*    margin: 0 1rem;*/
    /*    padding: 0.5rem 1.25rem;*/
    /*    box-sizing: border-box;*/
    /*    border-radius: 0.5rem;*/
    /*    font-family: Gibson, sans-serif;*/
    /*    text-decoration: none;*/
    /*    font-size: 0.7rem;*/
    /*    border: 0.05rem solid black;*/
    /*    font-weight: normal;*/
    /*    cursor: pointer;*/
    /*}*/

    /*.home_header_right-half > .home_header_right-half_link-button_opaque{*/
    /*    color: #363636;*/
    /*    margin: 0 1rem;*/
    /*    font-family: Gibson, sans-serif;*/
    /*    text-decoration: none;*/
    /*    border-radius: 0.5rem;*/
    /*    padding: 0.5rem 1.25rem;*/
    /*    box-sizing: border-box;*/
    /*    font-size: 0.7rem;*/
    /*    background-color: white;*/
    /*    font-weight: normal;*/
    /*    cursor: pointer;*/
    /*}*/

    /*-------------------------------Styles for first 100vh --------------------------------*/

    .home_flex_row_1{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        min-height: 25rem;
    }

    .home_flex_row_1_infobox_1{
        width: 50%;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: var(--header-height);
        box-sizing: border-box;
    }

    .home_flex_row_1_infobox_1 > *{
        max-width: 25rem;
    }

    .home_flex_row_1_infobox_1 > h2{
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        line-height: 1;
        width: 100%;
    }

    .home_flex_row_1_infobox_1 > h2 > span{
        color: #024E85;
    }

    .home_flex_row_1_infobox_1 > p{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 2rem 0;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .home_flex_row_1_signup{
        height: 3rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .home_flex_row_1_signup > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0 1rem 0 0;
        border: none;
        outline: none;
        width: auto;
        height: auto;
        flex-grow: 1;
        transition: background-color 0.3s linear;
    }

    .home_flex_row_1_signup > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .home_flex_row_1_signup > input:focus{
        background-color: #E2E2E2;
    }

    .home_flex_row_1_signup > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        margin-right: 2.5rem;
        cursor: pointer;
    }

    .home_flex_row_1_signup > .home_page_progress_container {
        width: 7rem;
        height: 100%;
        margin-right: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    /*-------------------------------Test series information styles ---------------------------*/

    .home_test_series_info{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .home_test_series_info > div:nth-child(even){
        background-color: #F4F4F4;
    }

    .home_test_series_info > div:nth-child(even):hover{
        background-color: white;
    }

    .home_test_series_info > div:nth-child(even) a{
        background-color: white;
    }

    .home_test_series_info > div:nth-child(even):hover a{
        background-color: #F4F4F4;
    }

    .home_test_series_info > div:nth-child(odd) a{
        background-color: #F4F4F4;
    }

    .home_test_series_info > div:hover p.home_test_series_info_item_title{
        transform: scale(0.83) translateX(-9%);
    }

    .home_test_series_info > div:hover p.home_test_series_info_item_text{
        opacity: 1;
        transform: scale(1);
    }

    .home_test_series_info_textbox_mobile{
        display: none;
    }

    .home_test_series_info_textbox_desktop{
        width: 50%;
        height: 25vw;
        min-height: calc(var(--min-allowed-width)*0.25);
        box-sizing: border-box;
        padding: 0 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .home_test_series_info_textbox_desktop > h2{
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .home_test_series_info_textbox_desktop > p{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 1.5rem 0 0;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .home_test_series_info_item{
        width: 25%;
        height: 25vw;
        min-height: 19rem;
        display: flex;
        flex-direction: column;
        padding: 2.5rem;
        box-sizing: border-box;
        position: relative;
        transition: all 0.1s ease;
    }

    .home_test_series_info_item:hover{
        background-color: white;
        transform: scale(1.2);
    }

    .home_test_series_info_item > img{
        width: 2.5rem;
        height: 2.5rem;
        align-self: flex-start;
        object-position: center;
        object-fit: contain;
    }

    .home_test_series_info_item > p.home_test_series_info_item_title{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        width: 60%;
        text-align: start;
        margin: 1.5rem 0 0;
        font-size: 1.6rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        transition: all 0.1s ease;
    }

    .home_test_series_info_item > p.home_test_series_info_item_text{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        font-size: 0.7rem;
        opacity: 0;
        transform: scale(0);
        margin: 0.75rem 0 0;
        transition: opacity 0.1s ease, transform 0.1s ease;
    }

    .home_test_series_info_item > a.home_test_series_info_item_action-button{
        width: 2rem;
        height: 2rem;
        max-width: 2.25rem;
        max-height: 2.25rem;
        align-self: flex-end;
        border-radius: 1.25rem;
        background-image: url("../Assets/round-transparent-bg-arrow.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg);
        cursor: pointer;
        margin-top: auto;
    }

    /*--------------------------------------Why Eval section --------------------------------*/

    h1.home_why_eval_title_main{
        color: black;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        font-size: 4rem;
        margin: 4rem 0;
    }

    /*Itrem 1*/

    .home_why_eval_item-1{
        width: 100%;
        height: 50vw;
        min-height: 25rem;
        display: flex;
        flex-direction: row;
        max-width: var(--max-allowed-width);
        max-height: calc(var(--max-allowed-width)/2);
        margin: auto;
    }
    .home_why_eval_item-1_imagebox{
        background-color: #024E85;
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: relative;
        padding: 15%;
        box-sizing: border-box;
    }


    .home_why_eval_item-1_imagebox > svg.blue_circle{
        position: absolute;
        top: 0;
        left: 0;
    }

    .home_why_eval_item-1_imagebox > img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        top: 0;
        left: 0;
    }

    .home_why_eval_item-1_textbox{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10%;
        box-sizing: border-box;
    }

    .home_why_eval_item-1_textbox > h2 {
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .home_why_eval_item-1_textbox > p {
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 1.5rem 0;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        width: 100%;
    }

    .home_why_eval_item-1_textbox > a {
        font-family: Josefinsans, sans-serif;
        align-self: flex-start;
        text-decoration: none;
        font-size: 0.75rem;
        color: black;
        padding: 0.75rem 2rem;
        border-radius: 0.75rem;
        background-color: #F4F5F7;
        outline: none;
        border: none;
        cursor: pointer;
    }


    /*Itrem 2*/
    .home_why_eval_item-2{
        --max-allowed-width: 1280px;
        width: 100%;
        height: 50vw;
        min-height: 25rem;
        display: flex;
        flex-direction: row;
        max-width: var(--max-allowed-width);
        max-height: calc(var(--max-allowed-width)/2);
        margin: auto;
    }

    .home_why_eval_item-2_imagebox{
        position: relative;
        background-color: #BC4830;
        width: 50%;
        height: 100%;
        overflow: hidden;
        padding: 15%;
        box-sizing: border-box;
    }

    .home_why_eval_item-2_imagebox > svg.red_circle{
        position: absolute;
        top: 0;
        left: 0;
    }

    .home_why_eval_item-2_imagebox > img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        top: 0;
        left: 0;
    }

    .home_why_eval_item-2_textbox{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10%;
        box-sizing: border-box;
    }

    .home_why_eval_item-2_textbox > h2 {
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .home_why_eval_item-2_textbox > p {
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 1.5rem 0;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        width: 100%;
    }

    .home_why_eval_item-2_textbox > a {
        font-family: Josefinsans, sans-serif;
        align-self: flex-start;
        text-decoration: none;
        font-size: 0.75rem;
        color: black;
        padding: 0.75rem 2rem;
        border-radius: 0.75rem;
        background-color: #F4F5F7;
        outline: none;
        border: none;
        cursor: pointer;
    }

    /*------------------------------ Testimonials section -------------------------*/

    .home_testimonials{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        position: relative;
    }

    svg.home_testimonials_background{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    svg.home_testimonials_background > rect{
        width: 40%;
        height: 100%;
        z-index: 0;
    }

    svg.home_testimonials_background > ellipse{
        z-index: 0;
        cx: 40%;
        cy: 50%;
        rx: 30vw;
        ry: 45vh;
        fill: white;
    }

    .home_testimonials_buttons{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 10%;
        height: 100%;
    }

    .home_testimonials_buttons_button-up{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
        background: white url("../Assets/round-transparent-bg-arrow.png") no-repeat center;
        background-size: contain;
        margin-bottom: 1.5rem;
        cursor: pointer;
        outline: none;
        border: none;
        z-index: 1;
    }

    .home_testimonials_buttons_button-down{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
        background: white url("../Assets/round-transparent-bg-arrow.png") no-repeat center;
        background-size: contain;
        transform: rotate(180deg);
        cursor: pointer;
        outline: none;
        border: none;
    }

    .home_testimonials_content {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1;
        justify-content: center;
        align-items: center;
        padding: 5% 20%;
        box-sizing: border-box;
    }

    .home_testimonials_content > h2{
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .home_testimonials_content_item_container{
        margin: 3rem 0 0;
        height: 60%;
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .home_testimonials_content_item{
        width: 100%;
        height: auto;
        position: absolute;
    }

    .home_testimonials_content_item > p.home_testimonials_content_item_text{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 1.5rem 0;
        font-size: 0.85rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        line-height: 1.2;
        width: 100%;
    }

    .home_testimonials_content_item > p.home_testimonials_content_item_name {
        color: black;
        font-family: Gibson, sans-serif;
        text-align: start;
        margin: 1.5rem 0;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
    }

    .home_testimonials_content_item > p.home_testimonials_content_item_name > span{
        color: #687086;
    }

    h1.home_achievements_title_main, h1.home_trusted_by_title{
        color: black;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        font-size: 4rem;
        margin: 4rem 0;
    }

    /*----------------------Setup account section-----------------------*/

    .home_setup_account_section {
        height: 15rem;
        width: 100%;
        background: #F9FAFB;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .home_setup_account_section > h2{
        text-align: start;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        letter-spacing: 0.1rem;
        margin: 0;
        width: 25rem;
        text-decoration: underline;
    }

    .home_setup_account_section > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0 1rem;
        border: none;
        outline: none;
        width: 15rem;
        height: auto;
        transition: background-color 0.3s linear;
    }

    .home_setup_account_section > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .home_setup_account_section > input:focus{
        background-color: #E2E2E2;
    }

    .home_setup_account_section > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        text-align: center;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        cursor: pointer;
    }
    .home_setup_account_section > .home_page_progress_container {
        width: 7rem;
        height: 2.25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}