@media (max-width: 768px){
    .login_page{
        width: 100%;
        height: auto;
        min-height: 100vh;
        background-color: #EEF1F6;
        box-sizing: border-box;
        position: absolute;
    }
    
    .login_page_content{
        width: 100%;
        height: 100%;
        padding: var(--header-height) 2rem 2.5rem;
        box-sizing: border-box;
    }
    
    .login_page_progress_container{
        /*NOTE: FOLLOWS STYLING OF login_page_button_1 class buttons*/
        margin: 2.5rem auto 0;
        height: 3rem;
    }
    
    /*-------------------------sign in section ------------------------*/
    
    .sign-in_card{
        width: 100%;
        margin: 2.5rem auto 0;
        height: auto;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 0 2.5rem #00000010;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;
        box-sizing: border-box;
    }
    
    .sign-in_card > h2{
        text-align: center;
        font-size: 2.2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0.25rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .login_page_input_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 1.5rem 0 0;
    }
    
    .login_page_input_item > p{
        width: 100%;
        color: black;
        font-family: Josefinsans, sans-serif;
        text-align: start;
        margin: 0 0 1rem 0.25rem;
        font-size: 0.6rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .login_page_input_item > input, .login_page_input_item > div{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: auto;
        transition: background-color 0.3s linear;
    }
    
    .login_page_input_item > div{
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .login_page_input_item> input:focus, .login_page_input_item > div:focus-within{
        background-color: #E2E2E2;
    }
    
    .login_page_input_item option{
        background-color: #F1F1F1;
    }
    
    button.login_page_button_1{
        width: 100%;
        height: 3rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 2.5rem auto 0;
    }
    
    button.login_page_button_2{
        width: 100%;
        color: #024E85;
        border: 0.1rem solid #024E85;
        font-family: Josefinsans, sans-serif;
        height: 3rem;
        text-align: center;
        font-size: 0.7rem;
        font-weight: normal;
        padding: 0.75rem 0;
        background-color: #00000000;
        border-radius: 1rem;
        outline: none;
        cursor: pointer;
        margin: 1rem auto;
    }
    
    
    .login_page_divider{
        width: 5rem;
        height: auto;
        position: relative;
        margin: 1rem auto 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .login_page_divider > span{
        height: 50%;
        width: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        border-top: 0.1rem solid black;
    }
    
    .login_page_divider_textbox {
        width: content-box;
        padding: 0 0.25rem;
        background-color: white;
        height: auto;
        z-index: 1;
    }
    
    .login_page_divider_textbox > p{
        text-align: center;
        font-size: 0.8rem;
        font-family: Josefinsans, sans-serif;
        color: black;
        margin: 0 auto;
        font-weight: normal;
        position: relative;
    }
    
    .sign-in_card > a{
        width: 100%;
    }
    
    a.login_page_link_text{
        text-align: center;
        font-size: 0.7rem;
        font-family: Josefinsans, sans-serif;
        color: #A09CB2;
        margin: auto auto 0;
        position: relative;
        cursor: pointer;
        text-decoration: none;
    }
    
    /*------------------ sign up section -------------------------*/
    
    .sign-up_card{
        width: 100%;
        margin: 2.5rem auto 0;
        height: content-box;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 0 2.5rem #00000010;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;
        box-sizing: border-box;
    }
    
    .sign-up_card > h2{
        text-align: center;
        font-size: 2.2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0.25rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .login_page_input_rows {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .login_page_input_rows > .login_page_input_item{
        width: 100%;
        margin: 1.5rem auto 0;
    }
    
    .sign-up_card > button.login_page_button_1{
        width: 100%;
    }
    
    .sign-up_card > p.login_page_link_text{
        text-align: center;
        font-size: 0.7rem;
        font-family: Josefinsans, sans-serif;
        color: #A09CB2;
        margin: 1.5rem auto 0;
        position: relative;
        text-decoration: none;
    }
    
    .sign-up_card > p.login_page_link_text > a{
        color: #024E85;
        cursor: pointer;
    }
    
    
    /*------------forgot password section --------------------------*/
    
    .forgot_password_card{
        width: 100%;
        margin: 2.5rem auto 0;
        height: auto;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 0 2.5rem #00000010;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;
        box-sizing: border-box;
    }
    
    .forgot_password_card > h2{
        text-align: center;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0.25rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .forgot_password_card > .login_page_input_item{
        margin: 2rem auto 0;
    }
    
    .forgot_password_card > a{
        width: 100%;
    }
}

@media (min-width: 769px){
    .login_page{
        width: 100%;
        height: auto;
        min-height: 100vh;
        background-color: #EEF1F6;
        box-sizing: border-box;
        position: absolute;
    }
    
    .login_page_content{
        width: 100%;
        height: 100%;
        padding: var(--header-height) 5rem 2.5rem;
        box-sizing: border-box;
    }
    
    .login_page_progress_container{
        /*NOTE: FOLLOWS STYLING OF login_page_button_1 class buttons*/
        margin: 2.5rem auto 0;
        height: 2.5rem;
    }
    
    /*-------------------------sign in section ------------------------*/
    
    .sign-in_card{
        width: 20rem;
        margin: 2.5rem auto 0;
        height: 27rem;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 0 2.5rem #00000010;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;
        box-sizing: border-box;
    }
    
    .sign-in_card > h2{
        text-align: center;
        font-size: 2.2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0.25rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .login_page_input_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 1rem 0 0;
    }
    
    .login_page_input_item > p{
        width: 100%;
        color: black;
        font-family: Josefinsans, sans-serif;
        text-align: start;
        margin: 0 0 0.5rem 0.25rem;
        font-size: 0.6rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .login_page_input_item > input, .login_page_input_item > div{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: auto;
        transition: background-color 0.3s linear;
    }
    
    .login_page_input_item > div{
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .login_page_input_item> input:focus, .login_page_input_item > div:focus-within{
        background-color: #E2E2E2;
    }
    
    .login_page_input_item option{
        background-color: #F1F1F1;
    }
    
    button.login_page_button_1{
        width: 100%;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 2.5rem auto 0;
    }
    
    button.login_page_button_2{
        width: 100%;
        color: #024E85;
        border: 0.1rem solid #024E85;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.7rem;
        font-weight: normal;
        padding: 0.75rem 0;
        background-color: #00000000;
        border-radius: 1rem;
        outline: none;
        cursor: pointer;
        margin: 0.5rem auto;
    }
    
    
    .login_page_divider{
        width: 5rem;
        height: auto;
        position: relative;
        margin: 0.75rem auto 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .login_page_divider > span{
        height: 50%;
        width: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        border-top: 0.1rem solid black;
    }
    
    .login_page_divider_textbox {
        width: content-box;
        padding: 0 0.25rem;
        background-color: white;
        height: auto;
        z-index: 1;
    }
    
    .login_page_divider_textbox > p{
        text-align: center;
        font-size: 0.8rem;
        font-family: Josefinsans, sans-serif;
        color: black;
        margin: 0 auto;
        font-weight: normal;
        position: relative;
    }
    
    .sign-in_card > a{
        width: 100%;
    }
    
    a.login_page_link_text{
        text-align: center;
        font-size: 0.7rem;
        font-family: Josefinsans, sans-serif;
        color: #A09CB2;
        margin: auto auto 0;
        position: relative;
        cursor: pointer;
        text-decoration: none;
    }
    
    /*------------------ sign up section -------------------------*/
    
    .sign-up_card{
        width: 40rem;
        margin: 2.5rem auto 0;
        height: content-box;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 0 2.5rem #00000010;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;
        box-sizing: border-box;
    }
    
    .sign-up_card > h2{
        text-align: center;
        font-size: 2.2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0.25rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .login_page_input_rows {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-evenly;
        align-items: center;
    }
    
    .login_page_input_rows > .login_page_input_item{
        width: calc(50% - 1.5rem);
        margin: 1.5rem auto 0;
    }
    
    .sign-up_card > button.login_page_button_1{
        width: 25rem;
    }
    
    .sign-up_card > p.login_page_link_text{
        text-align: center;
        font-size: 0.7rem;
        font-family: Josefinsans, sans-serif;
        color: #A09CB2;
        margin: 1.5rem auto 0;
        position: relative;
        text-decoration: none;
    }
    
    .sign-up_card > p.login_page_link_text > a{
        color: #024E85;
        cursor: pointer;
    }
    
    
    /*------------forgot password section --------------------------*/
    
    .forgot_password_card{
        width: 22rem;
        margin: 2.5rem auto 0;
        height: 20rem;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 0 2.5rem #00000010;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;
        box-sizing: border-box;
    }
    
    .forgot_password_card > h2{
        text-align: center;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0.25rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .forgot_password_card > .login_page_input_item{
        margin: auto auto 0;
    }
    
    .forgot_password_card > a{
        width: 100%;
    }
}