.home_trusted_by_container{
    margin: 50px auto;
    overflow: hidden;
    position: relative;
    max-width: 100%;
}

img.home_trusted_by_item{
    position: absolute;
    height: 100%;
    width: auto;
    object-fit: contain;
}