@media (max-width: 768px){
    .profile_page{
        width: 100%;
        height: auto;
        min-height: calc(100vh - var(--header-height));
        margin: var(--header-height) 0 0;
        box-sizing: border-box;
        padding: 1.5rem 10%;
    }
    
    .profile_page_tab_selection{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    .profile_page_tab_selection > h2{
        font-size: 1.5rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        opacity: 0.3;
        margin: 1.5rem 1rem 1.5rem 0;
        font-weight: bold;
        letter-spacing: 0.05rem;
        cursor: pointer;
        transition: color 0.1s ease, opacity 0.1s ease;
    }
    
    .profile_page_tab_selection > h2.selected{
        color: #024E85;
        opacity: 1;
    }
    
    .profile_page_tab_content{
        width: 100%;
        margin-top: 1.5rem;
    }
    
    
    /*----------------------------------------- Tests section --------------------------*/
    
    .profile_page_tests_tab{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }
    
    .profile_page_progress_container{
        width: 100%;
        height: 15rem;
    }
    
    .profile_page_tests_purchased, .profile_page_tests_not_purchased{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
    }

    .profile_page_tests_not_purchased > p.profile_page_tests_not_purchased_verify_email_text{
        font-size: 0.85rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0 auto 1rem 0;
        font-weight: normal;
        text-align: start; 
        border-radius: 0.5rem;
        padding: 1rem;
        box-sizing: border-box;
        background-color: #F7F9FB;
        line-height: 1.3;
        width: auto;
    }

    .profile_page_tests_not_purchased > p.profile_page_tests_not_purchased_verify_email_text > span{
        color: #0789a8;
        cursor: pointer;
        margin: 0 0 0 1rem;
    }
    
    .profile_page_tests_purchased > h3, .profile_page_tests_not_purchased > h3 {
        font-size: 1.5rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0 0 0.75rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
    }
    
    .profile_page_items_container{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }
    
    .profile_page_item{
        min-width: 15rem;
        width: 15rem;
        height: 15rem;
        margin: 0 2rem 2rem 0;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        box-sizing: border-box;
        background-color: #F7F9FB;
    }
    
    .profile_page_item > img{
        width: 2.5rem;
        height: 2.5rem;
        align-self: flex-start;
        object-position: center;
        object-fit: contain;
    }
    
    .profile_page_item > p.profile_page_item_title{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        width: 60%;
        text-align: start;
        margin: 1.5rem 0 0;
        font-size: 1.4rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        transition: all 0.1s ease;
    }
    
    .profile_page_item_content{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin: auto 0 1rem;
    }
    
    .profile_page_item_content > p.profile_page_item_text{
        color: black;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        font-size: 0.9rem;
        line-height: 1.3;
        margin: 0;
    }
    
    .profile_page_item_content > button.profile_page_item_action-button, .profile_page_item_content > a.profile_page_item_action-button{
        border-radius: 1.25rem;
        background-color: white;
        cursor: pointer;
        color: black;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        font-size: 0.7rem;
        margin: 0 1rem 0 auto;
        padding: 0.5rem 1rem;
        outline: none;
        border: none;
        text-decoration: none;
    }
    
    /*--------------------------------------------- reports section -----------------------------*/
    
    .profile_page_reports_tab{
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow-x: scroll;
        justify-content: flex-start;
        align-items: center;
        margin: auto 0 1rem;
    }
    
    .profile_page_reports_tab  p.profile_page_item_text{
        text-align: start;
        margin-top: auto;
        opacity: 0.5;
        font-size: 0.8rem;
        color: black;
        font-family: Gibson-light, sans-serif;
        line-height: 1.3;
    }
    
    .profile_page_reports_tab  .profile_page_item_content{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        margin-top: 0;
    }
    
    .profile_page_reports_tab  .profile_page_item_content > button.profile_page_item_action-button {
        margin: 0 0 0 1rem;
    }

    
    /* ------------------------------- PERSONAL INFO SECTION ----------------------- */
    .profile_page_info_tab{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
    }   

    .profile_page_info_tab_row{
        width: 100%;
        height: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
    }

    .profile_page_info_tab_row_label{
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
    }

    .profile_page_info_tab_row_label > p{
        text-align: start;
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }

    .profile_page_info_tab_row_content{
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .profile_page_info_tab_row_content > p{
        text-align: start;
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }

    .profile_page_info_tab_row_content > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        transition: background-color 0.3s linear;
    }

    .profile_page_info_tab_row_content > div {
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        transition: background-color 0.3s linear;
    }

    button.profile_page_info_tab_action_button {
        width: 10rem;
        height: 3rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 2.5rem auto 0;
    }

    button.profile_page_info_tab_action_button:disabled {
        opacity: 0.5;
        cursor: default;
    }

    .profile_page_info_tab_progress_container{
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2.5rem auto 0;
    }
    
    /*---------------------------------- MODAL SECTION ---------------------------*/
    
    .profile_page_modal{
        --profile_page_modal_width: 40rem;
        width: 100%;
        min-width: 20rem;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        box-sizing: border-box;
        position: relative;
    }
    
    .profile_page_modal_row-1{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        margin: 0 0 2rem;
    }
    
    .profile_page_modal_row-1 > h2{
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: normal;
        cursor: pointer;
    }
    
    .profile_page_modal_row-1 > button{
        height: 1.25rem;
        width: auto;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        position: absolute;
        top: 2rem;
        right: 1rem;
    }
    
    .profile_page_modal_row-1 > button > img{
        height: 100%;
        width: auto;
        object-position: center;
        object-fit: contain;
    }
    
    .profile_page_modal_row-2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
    }
    
    .profile_page_modal_row-2 > button{
        --profile_page_modal_button_size: calc((100vw - 32px - 12rem)/2);
        /* --profile_page_modal_button_size: 7rem; */
        width: var(--profile_page_modal_button_size);
        height: var(--profile_page_modal_button_size);
        min-width: 7rem;
        min-height: 7rem;
        background: #F7F9FB;
        border-radius: 1rem;
        border: 0.1rem solid white;
        text-align: center;
        outline: none;
        cursor: pointer;
        font-size: 1.3rem;
        font-family: Gibson-light, sans-serif;
        box-sizing: border-box;
        margin-bottom: 2rem;
        transition: color 0.2s ease, border 0.2s ease;
    }

    .profile_page_modal_row-2 > button:nth-child(odd){
        margin-right: 2rem;
    }
    
    .profile_page_modal_row-2 > button.selected{
        color: #024E85;
        border: 0.1rem solid #024E85;
    }
    
    .profile_page_modal_action_button{
        text-decoration: none;
        width: 100%;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 1rem 0 0;
        box-sizing: border-box;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
    }
}

@media (min-width: 769px){
    .profile_page{
        width: 100%;
        height: auto;
        min-height: calc(100vh - var(--header-height));
        margin: var(--header-height) 0 0;
        box-sizing: border-box;
        padding: 1.5rem 10%;
    }
    
    .profile_page_tab_selection{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    .profile_page_tab_selection > h2{
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        opacity: 0.3;
        margin: 1.5rem 2.5rem 1.5rem 0;
        font-weight: bold;
        letter-spacing: 0.05rem;
        cursor: pointer;
        transition: color 0.1s ease, opacity 0.1s ease;
    }
    
    .profile_page_tab_selection > h2.selected{
        color: #024E85;
        opacity: 1;
    }
    
    .profile_page_tab_content{
        width: 100%;
        margin-top: 1.5rem;
    }
    
    
    /*----------------------------------------- Tests section --------------------------*/
    
    .profile_page_tests_tab{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }
    
    .profile_page_progress_container{
        width: 100%;
        height: 15rem;
    }
    
    .profile_page_tests_purchased, .profile_page_tests_not_purchased{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
    }

    .profile_page_tests_not_purchased > p.profile_page_tests_not_purchased_verify_email_text{
        font-size: 0.9rem;
        font-family: Gibson-light, sans-serif;
        color: #000000aa;
        margin: 0 auto 1rem 0;
        font-weight: bold;
        text-align: start; 
        border-radius: 0.5rem;
        padding: 1rem;
        line-height: 1.4;
        box-sizing: border-box;
        background-color: #F7F9FB;
        width: auto;
    }

    .profile_page_tests_not_purchased > p.profile_page_tests_not_purchased_verify_email_text > span{
        color: #0789a8;
        cursor: pointer;
        margin: 0 0 0 1rem;
    }
    
    .profile_page_tests_purchased > h3, .profile_page_tests_not_purchased > h3 {
        font-size: 1.5rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0 0 0.75rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
    }
    
    .profile_page_items_container{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    
    .profile_page_item{
        width: 15rem;
        height: 15rem;
        margin: 0 2rem 2rem 0;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        box-sizing: border-box;
        background-color: #F7F9FB;
    }
    
    .profile_page_item > img{
        width: 2.5rem;
        height: 2.5rem;
        align-self: flex-start;
        object-position: center;
        object-fit: contain;
    }
    
    .profile_page_item > p.profile_page_item_title{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        width: 60%;
        text-align: start;
        margin: 1.5rem 0 0;
        font-size: 1.4rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        transition: all 0.1s ease;
    }
    
    .profile_page_item_content{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin: auto 0 1rem;
    }
    
    .profile_page_item_content > p.profile_page_item_text{
        color: black;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        font-size: 0.9rem;
        line-height: 1.3;
        margin: 0;
    }
    
    .profile_page_item_content > button.profile_page_item_action-button, .profile_page_item_content > a.profile_page_item_action-button{
        border-radius: 1.25rem;
        background-color: white;
        cursor: pointer;
        color: black;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        font-size: 0.7rem;
        margin: 0 1rem 0 auto;
        padding: 0.5rem 1rem;
        outline: none;
        border: none;
        text-decoration: none;
    }
    
    /*--------------------------------------------- reports section -----------------------------*/
    
    .profile_page_reports_tab{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin: auto 0 1rem;
        flex-wrap: wrap;
    }
    
    .profile_page_reports_tab  p.profile_page_item_text{
        text-align: start;
        margin-top: auto;
        opacity: 0.5;
        font-size: 0.8rem;
        color: black;
        font-family: Gibson-light, sans-serif;
        line-height: 1.3;
    }
    
    .profile_page_reports_tab  .profile_page_item_content{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        margin-top: 0;
    }
    
    .profile_page_reports_tab  .profile_page_item_content > button.profile_page_item_action-button {
        margin: 0 0 0 1rem;
    }

    /* ------------------------------- PERSONAL INFO SECTION ----------------------- */
    .profile_page_info_tab{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }   

    .profile_page_info_tab_row{
        width: 30rem;
        height: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
    }

    .profile_page_info_tab_row_label{
        width: 30%;
        height: 100%;
        padding: 1rem;
        box-sizing: border-box;
    }

    .profile_page_info_tab_row_label > p{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }

    .profile_page_info_tab_row_content{
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .profile_page_info_tab_row_content > p{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }

    .profile_page_info_tab_row_content > input, .profile_page_info_tab_row_content > div {
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        transition: background-color 0.3s linear;
    }

    button.profile_page_info_tab_action_button {
        width: 10rem;
        height: 3rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 2.5rem auto 0;
    }

    button.profile_page_info_tab_action_button:disabled {
        opacity: 0.5;
        cursor: default;
    }

    .profile_page_info_tab_progress_container{
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
        margin: 2.5rem auto 0;
        align-items: center;
    }


    /*---------------------------------- MODAL SECTION ---------------------------*/
    
    .profile_page_modal{
        --profile_page_modal_width: 40rem;
        width: var(--profile_page_modal_width);
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        box-sizing: border-box;
    }
    
    .profile_page_modal_row-1{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 1.5rem;
    }
    
    .profile_page_modal_row-1 > h2{
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
        cursor: pointer;
    }
    
    .profile_page_modal_row-1 > button{
        height: 1.25rem;
        width: auto;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
    }
    
    .profile_page_modal_row-1 > button > img{
        height: 100%;
        width: auto;
        object-position: center;
        object-fit: contain;
    }
    
    .profile_page_modal_row-2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    
    .profile_page_modal_row-2 > button{
        --profile_page_modal_button_size: 7.5rem;
        width: var(--profile_page_modal_button_size);
        height: var(--profile_page_modal_button_size);
        background: #F7F9FB;
        border-radius: 1rem;
        border: 0.1rem solid white;
        text-align: center;
        outline: none;
        cursor: pointer;
        font-size: 1.3rem;
        font-family: Gibson-light, sans-serif;
        box-sizing: border-box;
        transition: color 0.2s ease, border 0.2s ease;
    }
    
    .profile_page_modal_row-2 > button.selected{
        color: #024E85;
        border: 0.1rem solid #024E85;
    }
    
    .profile_page_modal_action_button{
        text-decoration: none;
        width: 20rem;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 1rem 0 0;
        box-sizing: border-box;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 2.5rem auto 0;
    }
}

