.App {
  text-align: center;
  width: 100%;
  height: auto;
}

iframe{
  width: 100%;
  height: 100vh;
}

.main_content{
  width: 100%;
  height: auto;
  position: relative;
  top: var(--header-height);
  left: 0;
  transition: filter 0.3s ease;
  background-color: white;
}

.main_content.navOpen, .main_content.navOpen > *{
  filter: blur(2px) brightness(50%);
}

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/
