@media (max-width: 768px){
    .Home_top_carousel{
        width: 100%;
        height: 35rem;
    }

    .home_top_carousel_imagebox {
        height: 70%;
        background-color: #8AC0DE;
        background-image: url("../../Assets/Homepage/Home1.jpg");
        /*background-size: auto 90%;*/
        /*background-position: center 150%;*/
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .home_top_carousel_row {
        display: flex;
        flex-direction: row;
        /*position: relative;*/
        height: 30%;
        width: 100%;
    }

    .home_top_carousel_row_textbox{
        width: 75%;
        height: 100%;
        background-color: #024E85;
        display: flex;
        flex-direction: row;
    }

    .home_top_carousel_row_dots{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 100%;
    }

    .home_top_carousel_row_text_container{
        padding: 0 4rem 0 0;
        width: 75%;
        height: 100%;
        overflow-y: hidden;
        position: relative;
    }

    .home_top_carousel_row_text_item{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
        position: absolute;
    }

    .home_top_carousel_row_emptybox{
        width: 25%;
        height: 100%;
        background-color: #FEB68E;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow-x: visible;
    }

    .home_top_carousel_row_button_up{
        --diameter: 2.5rem;
        cursor: pointer;
        position: relative;
        width: var(--diameter);
        height: var(--diameter);
        border-radius: calc(var(--diameter)/2);
        right: calc(var(--diameter)/2);
        margin-bottom: 1rem;
        background: url("../../Assets/round-transparent-bg-arrow.png") no-repeat center white;
        background-size: cover;
    }

    .home_top_carousel_row_button_down{
        --diameter: 2.5rem;
        position: relative;
        cursor: pointer;
        width: var(--diameter);
        height: var(--diameter);
        border-radius: calc(var(--diameter)/2);
        right: calc(var(--diameter)/2);
        transform: rotate(180deg);
        background: url("../../Assets/round-transparent-bg-arrow.png") no-repeat center white;
        background-size: cover;
    }
}

@media (min-width: 769px){
    .Home_top_carousel{
        width: 50%;
        height: 100%;
    }

    .home_top_carousel_imagebox {
        height: 70%;
        background-color: #8AC0DE;
        background-image: url("../../Assets/Homepage/Home1.jpg");
        /*background-size: auto 90%;*/
        /*background-position: center 150%;*/
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .home_top_carousel_row {
        display: flex;
        flex-direction: row;
        /*position: relative;*/
        height: 30%;
        width: 100%;
    }

    .home_top_carousel_row_textbox{
        width: 75%;
        height: 100%;
        background-color: #024E85;
        display: flex;
        flex-direction: row;
    }

    .home_top_carousel_row_dots{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 100%;
    }

    .home_top_carousel_row_text_container{
        padding: 0 2rem 0 0;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        position: relative;
        width: 75%;
        height: 100%;
        overflow-y: hidden;
    }

    /*.home_top_carousel_row_text_container > p{*/
    /*    position: relative;*/
    /*}*/

    .home_top_carousel_row_text_item{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
        position: absolute;
    }

    .home_top_carousel_row_emptybox{
        width: 25%;
        height: 100%;
        background-color: #FEB68E;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow-x: visible;
    }

    .home_top_carousel_row_button_up{
        --diameter: 2.5rem;
        cursor: pointer;
        position: relative;
        width: var(--diameter);
        height: var(--diameter);
        border-radius: calc(var(--diameter)/2);
        right: calc(var(--diameter)/2);
        margin-bottom: 1rem;
        background: url("../../Assets/round-transparent-bg-arrow.png") no-repeat center white;
        background-size: cover;
    }

    .home_top_carousel_row_button_down{
        --diameter: 2.5rem;
        position: relative;
        cursor: pointer;
        width: var(--diameter);
        height: var(--diameter);
        border-radius: calc(var(--diameter)/2);
        right: calc(var(--diameter)/2);
        transform: rotate(180deg);
        background: url("../../Assets/round-transparent-bg-arrow.png") no-repeat center white;
        background-size: cover;
    }
}

