@media (max-width: 768px){
    .view_report{
        --min-screen-height-reports: 30rem;
        --report-inner-pages-padding: 1.5rem;
        width: 100%;
        height: auto;
    }

    .view_report_languages{
        position: fixed;
        height: 2rem;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: row;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        border-radius: 0 0 0.25rem 0.25rem; 
        z-index: 50;       
        background-color: white;        
    }

    .view_report_language_item{
        height: 100%;
        padding: 0.3rem 1rem;        
        box-sizing: border-box;
    }

    .view_report_language_item > p{
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;        
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: center;
        cursor: pointer;
        margin:  0;
    }

    .view_report_language_item.selected > p{
        color: #024E85;
    }
    
    .view_report_page_progress_container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    /*--------------------------------------- page 1 title screen -----------------------------*/
    
    .view_report_page_1{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 30rem;
    }
    
    .view_report_page_1 > svg{
        position: absolute;
        width: 100%;
        height: 20%;
        left: 0;
        right: 0;
        top: 0;
        background-color: #024E85;
        z-index: 0;
    }
    
    .view_report_page_1 > .view_report_page_1_image_container{
        --report_page_1_image_container_padding: 3.5rem;
        width: 100%;
        height: 40%;
        padding: var(--report_page_1_image_container_padding);
        box-sizing: border-box;
        position: relative;
    }
    
    .view_report_page_1 > .view_report_page_1_image_container > img.image_large{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    
    .view_report_page_1 > .view_report_page_1_image_container > img.logo{
        width: 2.5rem;
        height: auto;
        position: absolute;
        top: calc(var(--report_page_1_image_container_padding) + 0.5rem);
        left: calc(var(--report_page_1_image_container_padding) + 0.5rem) ;
    }
    
    .view_report_page_1_text_container{
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }
    
    .view_report_page_1_text_container > .view_report_page_1_textbox{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    
    .view_report_page_1_text_container > .view_report_page_1_textbox > h2{
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #024E85;
        margin: 0 auto 1.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: center;
        width: 100%;
    }
    
    .view_report_page_1_text_container > .view_report_page_1_textbox > p{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0 auto 1rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: center;
        width: 100%;
    }
    
    .view_report_page_1_text_container > .view_report_page_1_textbox > p.subtitle{
        font-size: 0.8rem;
        font-family: Josefinsans, sans-serif;
        color: #062D4A;
        margin: 0 auto 0.75rem;
        font-weight: bold;
        width: 100%;
        letter-spacing: 0.05rem;
        text-align: center;
    }
    
    .view_report_page_1 > button.close_button{
        width: 2.5rem;
        height: auto;
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        position: absolute;
        top: 25%;
        right: 0.5rem;
    }
    
    .view_report_page_1 > button.close_button > img{
        width: 100%;
        height: auto;
    }
    
    
    /*------------------------------ page 2 general info -------------------------------*/
    
    .view_report_page_2 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    
    .view_report_page_2_first_half, .view_report_page_2_second_half{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
    }
    
    .view_report_page_2_first_half{
        background-color: #EFF6FF;
        align-items: center;
    }
    
    .view_report_page_2_first_half > .title_subtitle_item{
        width: 100%
    }
    
    .view_report_page_2_first_half > p.title{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 1rem 0 3rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: center;
        width: 100%;
    }
    
    .view_report_page_2_first_half > .title_subtitle_item > p.subtitle{
        font-size: 1.1rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0 auto 1rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
    }
    
    .view_report_page_2_first_half > .title_subtitle_item > p.text{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0 0 2rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
    }
    
    .view_report_page_2_first_half > p.note_text{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
        margin-bottom: 1rem;
    }
    
    .view_report_page_2_second_half{
        background-color: #024E85;
        justify-content: center;
        align-items: center;
    }
    
    .view_report_page_2_second_half > p.title{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        margin: 0 0 2.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: center;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_2_second_half > p.text{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    
    /*-------------------------------- page 3 career interest profile ------------------------*/
    .view_report_page_3{
        width: 100%;
        height: auto;
        min-height: 100vh;
        padding: var(--report-inner-pages-padding);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .view_report_page_3 > .top_row{
        --h2-width: 20%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .view_report_page_3 > .top_row > h2{
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #024E85;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: center;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_3 > .top_row > p{
        font-size: 0.9rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 2rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: center;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_3 > .graphs{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: 1.5rem;
    }
    
    .view_report_page_3 > .graphs > .column{
        width: 100%;
        height: auto;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    
    
    .view_report_page_3 > .graphs > .column > .graph_item{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > p.graph_text{
        font-size: 0.9rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 auto 0 0;
        width: 30%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > .graph_bar{
        width: calc(70% - 1.5rem);
        height: 1.5rem;
        border-radius: 0.5rem;
        background-color: #F4F5F7;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        overflow: hidden;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > .graph_bar > svg{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > .graph_bar > p.graph_number{
        position: relative;
        width: content-box;
        align-self: center;
        text-align: end;
        right: 1rem;
        color: #024E85;
        font-family: Gibson-light, sans-serif;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.3;
    }
    
    .view_report_page_3 > .graph_interpretation{
        --h2-width: 20%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    .view_report_page_3 > .graph_interpretation > p.title{
        font-size: 1.5rem;
        font-family: Gibson-light, sans-serif;
        color: #024E85;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: center;
        line-height: 1.3;
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1rem 1rem 1rem 0;
        width: calc(50% - 1rem);
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item:last-child{
        margin: 0;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > p.text{
        width: calc(100% - 4.5rem);
        flex-shrink: 1;
        align-self: center;
        text-align: start;
        color: #024E85;
        font-family: Gibson-light, sans-serif;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.3;
        margin: 0;
        font-size: 0.85rem;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .color_box{
        width: 2rem;
        height: 2rem;
        border-radius: 0.5rem;
        margin-left: 0.5rem;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .green{
        background-color: #B5F2DB;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .blue{
        background-color: #B5D0F2
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .yellow{
        background-color: #F2E6B5
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .red{
        background-color: #F2B5B5
    }
    
    /*-------------------------- page 4 recommendations ---------------------------*/
    
    .view_report_page_4{
        background-color: #F0FFF6;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: var(--report-inner-pages-padding);
    }
    
    .view_report_page_4.orange{
        background-color: #FFF8EF;
    }
    
    .view_report_page_4 > .top_row{
        --h2-width: 20%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 2.5rem;
    }
    
    .view_report_page_4 > .top_row > h2{
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: #2C905A;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: center;
        line-height: 1.3;
        /*width: var(--h2-width);*/
        width: 100%;
    }
    
    .view_report_page_4 > .top_row.orange > h2{
        color: #A35727;
    }
    
    /*.view_report_page_4 > .top_row > p{*/
    /*    font-size: 0.8rem;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    color: #2D2F31;*/
    /*    margin: 0;*/
    /*    font-weight: normal;*/
    /*    letter-spacing: 0.05rem;*/
    /*    text-align: start;*/
    /*    line-height: 1.3;*/
    /*    width: calc(100% - var(--h2-width) - 2.5rem);*/
    /*}*/
    
    .view_report_page_4 > p.large_text{
        font-size: 1.1rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 2.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: center;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_recommendation_item{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    
    .view_report_page_recommendation_item > h2 {
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: #2C905A;
        margin: 0 0 1.5rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    .orange .view_report_page_recommendation_item > h2 {
        color: #A35727
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem{
        width: 100%;
        box-sizing: border-box;
        padding: 0 0.5rem;
        display: flex;
        flex-direction: column;
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem > p.subtitle{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 1rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem > p.paragraph, .view_report_page_recommendation_item > p.paragraph{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem > p.paragraph.no-margin{
        margin-bottom: 0
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem > p.paragraph.no-margin:last-child {
        margin-bottom: 1.5rem;
    }
    
    .view_report_page_recommendation_item > p.paragraph{
        margin-left: 0.5rem;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
        flex-wrap: wrap;
        margin-bottom: 2.5rem;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item > p.view_reports_page_4_graph_text{
        font-size: 0.9rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 auto 0 0;
        width: 20%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item > .view_reports_page_4_graph_bar{
        width: calc(70% - 1.5rem);
        height: 1.5rem;
        border-radius: 0.5rem;
        background-color: #DEF5E7;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        overflow: hidden;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item > .view_reports_page_4_graph_bar > svg{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #AAE6BC;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item > .view_reports_page_4_graph_bar > p.view_reports_page_4_graph_number{
        position: relative;
        width: content-box;
        align-self: center;
        text-align: end;
        right: 1rem;
        color: #2D905B;
        font-family: Gibson-light, sans-serif;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.3;
    }
    
    /*---------------------------------- page 5 job interest classification ---------------------------------*/
    .view_report_page_5{
        width: 100%;
        padding: var(--report-inner-pages-padding);
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        background-color: #F7FAFF;
    }
    
    .view_report_page_5 > .top_row{
        --h2-width: 20%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 2.5rem;
    }
    
    .view_report_page_5 > .top_row > h2{
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: #024E85;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: center;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_5 > .top_row > p{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: center;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_5 > p.large_text{
        font-size: 1.1rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 2.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_5 > p.note_text {
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
        margin-bottom: 2rem;
        font-style: italic;
    }
    
    .view_report_page_summary_item{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    
    .view_report_page_summary_item > h2 {
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: #2C905A;
        margin: 0 0 1.5rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem > p.subtitle{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 1rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem  p.paragraph, .view_report_page_summary_item  p.paragraph{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem > .career_options{
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem > .career_options > p.paragraph.no-margin{
        width: 100%;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem  p.paragraph.no-margin{
        margin-bottom: 0
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table{
        width: 100%;
        height: auto;
        margin: 1rem 0 1.5rem;
        display: flex;
        flex-direction: column;
        border-bottom: 0.05rem solid #3b404e;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_title_row,
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_item_row{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        border-right: 0.05rem solid #3b404e;
        border-top: 0.05rem solid #3b404e;
        border-left: 0.05rem solid #3b404e;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_title_row > .label,
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_item_row > .label{
        width: 30%;
        max-width: 20rem;
        height: auto;
        min-height: 100%;
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        padding: 0.75rem 0.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        border-right: 0.05rem solid #3b404e;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_title_row > .value,
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_item_row > .value{
        width: 30%;
        flex-grow: 1;
        height: auto;
        min-height: 100%;
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        padding: 0.75rem 0.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table >.table_title_row > .label,
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table >.table_title_row > .value{
        font-weight: bold;
        font-size: 1rem;
    }
    
    
    
    
    /*.view_report_page_5_classification_item{*/
    /*    width: 100%;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    margin-bottom: 2.5rem;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > h2 {*/
    /*    font-size: 1.7rem;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    color: #024E85;*/
    /*    margin: 0 0 1.5rem;*/
    /*    font-weight: bold;*/
    /*    letter-spacing: 0.1rem;*/
    /*    text-align: start;*/
    /*    line-height: 1.3;*/
    /*    width: 100%;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > .view_report_page_5_classification_item_content{*/
    /*    width: 100%;*/
    /*    box-sizing: border-box;*/
    /*    padding: 0 2.5rem;*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*    justify-content: space-between;*/
    /*    border-bottom: 0.05rem solid #326F9D88;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > .view_report_page_5_classification_item_content > .view_report_page_5_classification_item_content_subitem{*/
    /*    width: 25%;*/
    /*    height: auto;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    margin-bottom: 2.5rem;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > .view_report_page_5_classification_item_content > .view_report_page_5_classification_item_content_subitem > p.title{*/
    /*    font-size: 1.4rem;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    color: #2D2F31;*/
    /*    margin: 0 0 1.5rem;*/
    /*    font-weight: bold;*/
    /*    letter-spacing: 0.05rem;*/
    /*    text-align: start;*/
    /*    line-height: 1.3;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > .view_report_page_5_classification_item_content > .view_report_page_5_classification_item_content_subitem > p.text{*/
    /*    font-size: 0.9rem;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    color: #2D2F31;*/
    /*    margin: 0 0 0.5rem;*/
    /*    font-weight: normal;*/
    /*    letter-spacing: 0.05rem;*/
    /*    text-align: start;*/
    /*    line-height: 1.2;*/
    /*}*/
    
    
    /*------------------------------ page 6 contact -------------------------*/
    
    .view_report_page_6{
        width: 100%;
        padding: var(--report-inner-pages-padding);
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .view_report_page_6 > img{
        display: none;
    }
    
    .view_report_page_6 > p{
        width: 100%;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: center;
        line-height: 1.5;
    }
    
    .view_report_page_6 > p > span{
        color: #024E85;
    }    
}

@media (min-width: 769px){
    .view_report{
        --min-screen-height-reports: 30rem;
        --report-inner-pages-padding: 2.5rem;
        width: 100%;
        height: auto;
    }

    .view_report_languages{
        position: fixed;
        height: 2rem;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: row;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        border-radius: 0 0 0.25rem 0.25rem; 
        z-index: 50;       
        background-color: white;        
    }

    .view_report_language_item{
        height: 100%;
        padding: 0.3rem 1rem;        
        box-sizing: border-box;
    }

    .view_report_language_item > p{
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;        
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: center;
        cursor: pointer;
        margin:  0;
    }

    .view_report_language_item.selected > p{
        color: #024E85;
    }
    
    .view_report_page_progress_container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    /*--------------------------------------- page 1 title screen -----------------------------*/
    
    .view_report_page_1{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100vh;
        min-height: var(--min-screen-height-reports);
    }
    
    .view_report_page_1 > svg{
        position: absolute;
        width: 20%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #024E85;
        z-index: 0;
    }
    
    .view_report_page_1 > .view_report_page_1_image_container{
        --report_page_1_image_container_padding: 2.5rem;
        width: 50%;
        height: 100%;
        padding: var(--report_page_1_image_container_padding);
        box-sizing: border-box;
        position: relative;
    }
    
    .view_report_page_1 > .view_report_page_1_image_container > img.image_large{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    
    .view_report_page_1 > .view_report_page_1_image_container > img.logo{
        width: 2.5rem;
        height: auto;
        position: absolute;
        top: calc(var(--report_page_1_image_container_padding) + 0.5rem);
        left: calc(var(--report_page_1_image_container_padding) + 0.5rem) ;
    }
    
    .view_report_page_1_text_container{
        width: 50%;
        height: 100%;
        box-sizing: border-box;
    }
    
    .view_report_page_1_text_container > .view_report_page_1_textbox{
        width: 20rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    
    .view_report_page_1_text_container > .view_report_page_1_textbox > h2{
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #024E85;
        margin: 0 auto 1.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
    }
    
    .view_report_page_1_text_container > .view_report_page_1_textbox > p{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0 auto 1rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
    }
    
    .view_report_page_1_text_container > .view_report_page_1_textbox > p.subtitle{
        font-size: 0.8rem;
        font-family: Josefinsans, sans-serif;
        color: #062D4A;
        margin: 0 auto 0.75rem;
        font-weight: bold;
        width: 100%;
        letter-spacing: 0.05rem;
        text-align: start;
    }
    
    .view_report_page_1 > button.close_button{
        width: 2.5rem;
        height: auto;
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        position: absolute;
        top: 2.5rem;
        right: 2.5rem;
    }
    
    .view_report_page_1 > button.close_button > img{
        width: 100%;
        height: auto;
    }
    
    
    /*------------------------------ page 2 general info -------------------------------*/
    
    .view_report_page_2 {
        width: 100%;
        height: 100vh;
        min-height: var(--min-screen-height-reports);
        display: flex;
        flex-direction: row;
    }
    
    .view_report_page_2_first_half, .view_report_page_2_second_half{
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    
    .view_report_page_2_first_half{
        background-color: #EFF6FF;
        justify-content: space-evenly;
        align-items: center;
    }
    
    .view_report_page_2_first_half > .title_subtitle_item{
        width: 100%
    }
    
    .view_report_page_2_first_half > p.title{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
    }
    
    .view_report_page_2_first_half > .title_subtitle_item > p.subtitle{
        font-size: 1.1rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0 auto 1rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
    }
    
    .view_report_page_2_first_half > .title_subtitle_item > p.text{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
    }
    
    .view_report_page_2_first_half > p.note_text{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
        margin-bottom: 1rem;
    }
    
    .view_report_page_2_second_half{
        background-color: #024E85;
        justify-content: center;
        align-items: center;
    }
    
    .view_report_page_2_second_half > p.title{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        margin: 0 0 2.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_2_second_half > p.text{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    
    /*-------------------------------- page 3 career interest profile ------------------------*/
    .view_report_page_3{
        width: 100%;
        height: auto;
        min-height: 100vh;
        padding: var(--report-inner-pages-padding);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .view_report_page_3 > .top_row{
        --h2-width: 20%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .view_report_page_3 > .top_row > h2{
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #024E85;
        margin: 0 2.5rem 0 0;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: start;
        line-height: 1.3;
        width: var(--h2-width);
    }
    
    .view_report_page_3 > .top_row > p{
        font-size: 0.9rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        width: calc(100% - var(--h2-width) - 2.5rem);
    }
    
    .view_report_page_3 > .graphs{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: auto;
        margin-bottom: 1.5rem;
    }
    
    .view_report_page_3 > .graphs > .column{
        width: calc(50% - 5rem);
        height: auto;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    
    
    .view_report_page_3 > .graphs > .column > .graph_item{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > p.graph_text{
        font-size: 0.9rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 auto 0 0;
        width: 30%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > .graph_bar{
        width: calc(70% - 1.5rem);
        height: 1.5rem;
        border-radius: 0.5rem;
        background-color: #F4F5F7;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        overflow: hidden;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > .graph_bar > svg{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > .graph_bar > p.graph_number{
        position: relative;
        width: content-box;
        align-self: center;
        text-align: end;
        right: 1rem;
        color: #024E85;
        font-family: Gibson-light, sans-serif;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.3;
    }
    
    .view_report_page_3 > .graph_interpretation{
        --h2-width: 20%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
    }
    
    .view_report_page_3 > .graph_interpretation > p.title{
        font-size: 1.3rem;
        font-family: Gibson-light, sans-serif;
        color: #024E85;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: start;
        line-height: 1.3;
        width: var(--h2-width);
        flex-grow: 0;
        flex-shrink: 0;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin: 0 2.5rem 0 0;
        width: calc((100% - 10rem)/4);
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item:last-child{
        margin: 0;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > p.text{
        width: calc(100% - 3.5rem);
        flex-shrink: 1;
        align-self: center;
        text-align: start;
        color: #024E85;
        font-family: Gibson-light, sans-serif;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.3;
        margin: 0;
        font-size: 0.8rem;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .color_box{
        width: 2rem;
        height: 2rem;
        border-radius: 0.5rem;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .green{
        background-color: #B5F2DB;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .blue{
        background-color: #B5D0F2
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .yellow{
        background-color: #F2E6B5
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .red{
        background-color: #F2B5B5
    }
    
    /*-------------------------- page 4 recommendations ---------------------------*/
    
    .view_report_page_4{
        background-color: #F0FFF6;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: var(--report-inner-pages-padding);
    }
    
    .view_report_page_4.orange{
        background-color: #FFF8EF;
    }
    
    .view_report_page_4 > .top_row{
        --h2-width: 20%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 2.5rem;
    }
    
    .view_report_page_4 > .top_row > h2{
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: #2C905A;
        margin: 0 2.5rem 0 0;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: start;
        line-height: 1.3;
        /*width: var(--h2-width);*/
        width: auto
    }
    
    .view_report_page_4 > .top_row.orange > h2{
        color: #A35727;
    }
    
    /*.view_report_page_4 > .top_row > p{*/
    /*    font-size: 0.8rem;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    color: #2D2F31;*/
    /*    margin: 0;*/
    /*    font-weight: normal;*/
    /*    letter-spacing: 0.05rem;*/
    /*    text-align: start;*/
    /*    line-height: 1.3;*/
    /*    width: calc(100% - var(--h2-width) - 2.5rem);*/
    /*}*/
    
    .view_report_page_4 > p.large_text{
        font-size: 1.1rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 2.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_recommendation_item{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    
    .view_report_page_recommendation_item > h2 {
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: #2C905A;
        margin: 0 0 1.5rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    .orange .view_report_page_recommendation_item > h2 {
        color: #A35727
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem{
        width: 100%;
        box-sizing: border-box;
        padding: 0 2.5rem;
        display: flex;
        flex-direction: column;
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem > p.subtitle{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 1rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem > p.paragraph, .view_report_page_recommendation_item > p.paragraph{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem > p.paragraph.no-margin{
        margin-bottom: 0
    }
    
    .view_report_page_recommendation_item > .view_report_page_recommendation_subitem > p.paragraph.no-margin:last-child {
        margin-bottom: 1.5rem;
    }
    
    .view_report_page_recommendation_item > p.paragraph{
        margin-left: 2.5rem;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: auto;
        flex-wrap: wrap;
        margin-bottom: 2.5rem;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item{
        width: calc(50% - 5rem);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item > p.view_reports_page_4_graph_text{
        font-size: 0.9rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 auto 0 0;
        width: 20%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item > .view_reports_page_4_graph_bar{
        width: calc(80% - 1.5rem);
        height: 1.5rem;
        border-radius: 0.5rem;
        background-color: #DEF5E7;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        overflow: hidden;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item > .view_reports_page_4_graph_bar > svg{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #AAE6BC;
    }
    
    .view_report_page_4 > .view_reports_page_4_graphs > .view_reports_page_4_graph_item > .view_reports_page_4_graph_bar > p.view_reports_page_4_graph_number{
        position: relative;
        width: content-box;
        align-self: center;
        text-align: end;
        right: 1rem;
        color: #2D905B;
        font-family: Gibson-light, sans-serif;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.3;
    }
    
    /*---------------------------------- page 5 summary (earlier job interest classification) ---------------------------------*/
    .view_report_page_5{
        width: 100%;
        padding: var(--report-inner-pages-padding);
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        background-color: #F7FAFF;
    }
    
    .view_report_page_5 > .top_row{
        --h2-width: 20%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 2.5rem;
    }
    
    .view_report_page_5 > .top_row > h2{
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: #024E85;
        margin: 0 2.5rem 0 0;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: start;
        line-height: 1.3;
        width: var(--h2-width);
    }
    
    .view_report_page_5 > .top_row > p{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        width: calc(100% - var(--h2-width) - 2.5rem);
    }
    
    .view_report_page_5 > p.large_text{
        font-size: 1.1rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 2.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_5 > p.note_text {
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        width: 100%;
        margin-bottom: 2rem;
        font-style: italic;
    }
    
    .view_report_page_summary_item{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    
    .view_report_page_summary_item > h2 {
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: #2C905A;
        margin: 0 0 1.5rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: start;
        line-height: 1.3;
        width: 100%;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem{
        width: 100%;
        box-sizing: border-box;
        padding: 0 2.5rem;
        display: flex;
        flex-direction: column;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem > p.subtitle{
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 1rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem  p.paragraph, .view_report_page_summary_item  p.paragraph{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem > .career_options{
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem > .career_options > p.paragraph.no-margin{
        width: calc(50% - 1rem);
        margin-right: 1rem;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem  p.paragraph.no-margin{
        margin-bottom: 0
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table{
        width: 80%;
        height: auto;
        margin: 1rem auto 1.5rem;
        display: flex;
        flex-direction: column;
        border-bottom: 0.05rem solid #3b404e;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_title_row,
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_item_row{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        border-right: 0.05rem solid #3b404e;
        border-top: 0.05rem solid #3b404e;
        border-left: 0.05rem solid #3b404e;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_title_row > .label,
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_item_row > .label{
        width: 30%;
        max-width: 20rem;
        height: auto;
        min-height: 100%;
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        padding: 0.75rem 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
        border-right: 0.05rem solid #3b404e;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_title_row > .value,
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table > .table_item_row > .value{
        width: 30%;
        flex-grow: 1;
        height: auto;
        min-height: 100%;
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        padding: 0.75rem 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table >.table_title_row > .label,
    .view_report_page_summary_item > .view_report_page_summary_subitem >.view_report_page_summary_subitem_table >.table_title_row > .value{
        font-weight: bold;
        font-size: 1rem;
    }
    
    
    
    
    /*.view_report_page_5_classification_item{*/
    /*    width: 100%;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    margin-bottom: 2.5rem;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > h2 {*/
    /*    font-size: 1.7rem;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    color: #024E85;*/
    /*    margin: 0 0 1.5rem;*/
    /*    font-weight: bold;*/
    /*    letter-spacing: 0.1rem;*/
    /*    text-align: start;*/
    /*    line-height: 1.3;*/
    /*    width: 100%;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > .view_report_page_5_classification_item_content{*/
    /*    width: 100%;*/
    /*    box-sizing: border-box;*/
    /*    padding: 0 2.5rem;*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*    justify-content: space-between;*/
    /*    border-bottom: 0.05rem solid #326F9D88;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > .view_report_page_5_classification_item_content > .view_report_page_5_classification_item_content_subitem{*/
    /*    width: 25%;*/
    /*    height: auto;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    margin-bottom: 2.5rem;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > .view_report_page_5_classification_item_content > .view_report_page_5_classification_item_content_subitem > p.title{*/
    /*    font-size: 1.4rem;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    color: #2D2F31;*/
    /*    margin: 0 0 1.5rem;*/
    /*    font-weight: bold;*/
    /*    letter-spacing: 0.05rem;*/
    /*    text-align: start;*/
    /*    line-height: 1.3;*/
    /*}*/
    
    /*.view_report_page_5_classification_item > .view_report_page_5_classification_item_content > .view_report_page_5_classification_item_content_subitem > p.text{*/
    /*    font-size: 0.9rem;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    color: #2D2F31;*/
    /*    margin: 0 0 0.5rem;*/
    /*    font-weight: normal;*/
    /*    letter-spacing: 0.05rem;*/
    /*    text-align: start;*/
    /*    line-height: 1.2;*/
    /*}*/
    
    
    /*------------------------------ page 6 contact -------------------------*/
    
    .view_report_page_6{
        width: 100%;
        padding: var(--report-inner-pages-padding);
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .view_report_page_6 > img{
        width: 40%;
        height: auto;
    }
    
    .view_report_page_6 > p{
        width: calc(60% - 5rem);
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        font-weight: bold;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 2;
    }
    
    .view_report_page_6 > p > span{
        color: #024E85;
    }
}

@media (max-width: 900px) and (min-width: 769px){
    .view_report_page_3 > .graphs{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: 1.5rem;
    }
    
    .view_report_page_3 > .graphs > .column{
        width: 100%;
        height: auto;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    
    
    .view_report_page_3 > .graphs > .column > .graph_item{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > p.graph_text{
        font-size: 0.9rem;
        font-family: Gibson-light, sans-serif;
        color: #2D2F31;
        margin: 0 auto 0 0;
        width: 30%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        text-align: start;
        line-height: 1.3;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > .graph_bar{
        width: calc(70% - 1.5rem);
        height: 1.5rem;
        border-radius: 0.5rem;
        background-color: #F4F5F7;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        overflow: hidden;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > .graph_bar > svg{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
    
    .view_report_page_3 > .graphs > .column > .graph_item > .graph_bar > p.graph_number{
        position: relative;
        width: content-box;
        align-self: center;
        text-align: end;
        right: 1rem;
        color: #024E85;
        font-family: Gibson-light, sans-serif;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.3;
    }
    
    .view_report_page_3 > .graph_interpretation{
        --h2-width: 20%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        flex-wrap: wrap;
    }
    
    .view_report_page_3 > .graph_interpretation > p.title{
        font-size: 1.5rem;
        font-family: Gibson-light, sans-serif;
        color: #024E85;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.1rem;
        text-align: center;
        line-height: 1.3;
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1rem 1rem 1rem 0;
        width: calc(50% - 1rem);
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item:last-child{
        margin: 0;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > p.text{
        width: calc(100% - 4.5rem);
        flex-shrink: 1;
        align-self: center;
        text-align: start;
        color: #024E85;
        font-family: Gibson-light, sans-serif;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.3;
        margin: 0;
        font-size: 0.85rem;
    }
    
    .view_report_page_3 > .graph_interpretation > .graph_interpretation_item > .color_box{
        width: 2rem;
        height: 2rem;
        border-radius: 0.5rem;
        margin-left: 0.5rem;
    }
}