:root{
    --header-height: 100px;
}


@media (max-width: 768px){
    .header{
        width: 100vw;
        height: var(--header-height);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        box-sizing: border-box;
    }

    .header-content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .header_left-half{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 2rem;
        box-sizing: border-box;
    }

    .header_left-half > a.header_logo{
        height: 2rem;
        width: 2rem;
        cursor: pointer;
        background: url("../Assets/Eval-logo.png") no-repeat center;
        background-size: contain;
        display: block;
    }

    .header_left-half > a{
        display: none;
    }

    .header_right-half{
        width: 50%;
        height: 100%;
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0 2rem;
    }

    .header_right-half > .header_right-half_link-text{
        display: none;
    }

    .header_right-half > .header_right-half_link-button_transparent{
        display: none;
    }

    .header_right-half > .header_right-half_link-button_opaque{
        display: none;
    }

    .header_right-half > .header_right_hamburger{
        height: 2rem;
        width: 2rem;
        cursor: pointer;
        background: transparent url("../Assets/hamburger.png") no-repeat center;
        background-size: contain;
        display: block;
        outline: none;
        border: none;
    }
}

@media (min-width: 769px) {
    .header{
        width: 100%;
        height: var(--header-height);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        box-sizing: border-box;
    }

    .header-content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .header_left-half{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 2rem;
        box-sizing: border-box;
    }

    .header_left-half > a.header_logo{
        height: 2rem;
        width: 2rem;
        cursor: pointer;
        background: url("../Assets/Eval-logo.png") no-repeat center;
        background-size: contain;
    }

    .header_left-half > a{
        color: black;
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
    }

    .header_left-half > a:hover{
        color: #024E85;
    }

    .header_right-half{
        width: 50%;
        height: 100%;
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0 2rem;
    }

    .header_right-half > .header_right-half_link-text{
        /*color: #363636;*/
        /*font-family: Gibson-light, sans-serif;*/
        /*margin: 0 1rem;*/
        /*font-size: 0.8rem;*/
        /*font-weight: normal;*/
        /*text-decoration: underline;*/
        /*cursor: pointer;*/
        color: #363636;
        margin: 0 1rem;
        font-family: Gibson, sans-serif;
        text-decoration: none;
        border-radius: 0.5rem;
        padding: 0.5rem 1.25rem;
        box-sizing: border-box;
        font-size: 0.7rem;
        background-color: white;
        font-weight: normal;
        cursor: pointer;
        outline: none;
        border: none;
    }

    .header_right-half > .header_right-half_link-button_transparent{
        color: #363636;
        margin: 0 1rem;
        padding: 0.5rem 1.25rem;
        box-sizing: border-box;
        border-radius: 0.5rem;
        font-family: Gibson, sans-serif;
        text-decoration: none;
        font-size: 0.7rem;
        border: 0.01rem solid black;
        font-weight: normal;
        cursor: pointer;
    }

    .header_right-half > .header_right-half_link-button_opaque{
        color: #363636;
        margin: 0 1rem;
        font-family: Gibson, sans-serif;
        text-decoration: none;
        border-radius: 0.5rem;
        padding: 0.5rem 1.25rem;
        box-sizing: border-box;
        font-size: 0.7rem;
        background-color: white;
        font-weight: normal;
        cursor: pointer;
        outline: none;
        border: none;
    }
    .header_right_hamburger{
        display: none;
    }
}