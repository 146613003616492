@media (max-width: 768px){
    .checkout_page{
        width: 100%;
        min-height: calc(100vh - var(--header-height));
        margin-top: var(--header-height);
        background-color: #EEF1F6;
        box-sizing: border-box;
        height: 100%;
        padding: 2.5rem 2rem;
    }
    
    .checkout_page_card{
        width: 100%;
        margin: 0;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 0 2.5rem #00000010;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;
        box-sizing: border-box;
    }
    
    .checkout_page_card > h2{
        text-align: center;
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .checkout_item_name{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        padding: 1.5rem 2rem;
        background-color: #EEF1F6;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 0.75rem;
    }
    
    .checkout_item_name > img{
        height: 2.5rem;
        width: auto;
    }
    
    .checkout_item_name > p{
        text-align: start;
        font-size: 1.4rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        max-width: 60%;
    }
    
    .checkout_page_card_input_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 1rem 0 0;
    }
    
    .checkout_page_card_input_item > p{
        width: 100%;
        color: black;
        font-family: Josefinsans, sans-serif;
        text-align: start;
        margin: 1.5rem 0 0.5rem 0.25rem;
        font-size: 0.6rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .checkout_page_card_input_item > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: auto;
        transition: background-color 0.3s linear;
    }
    
    .checkout_page_card_input_item > input:focus{
        background-color: #E2E2E2;
    }
    
    .checkout_page_price{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 0.05rem solid black;
        margin: 1.5rem 0 0;
    }
    
    .checkout_page_price > p.label{
        color: black;
        font-family: Josefinsans, sans-serif;
        text-align: start;
        margin: 1rem 0 0;
        font-size: 0.6rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .checkout_page_price > p.value{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 1rem 0 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .checkout_page_card >  button.checkout_page_card_action{
        /*WIDTH HEIGHT AND MARGIN SAME AS .checkout_page_card_progress_container*/
        width: 100%;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 2.5rem auto 0;
    }
    
    .checkout_page_card_progress_container{
        /*WIDTH HEIGHT AND MARGIN SAME AS .checkout_page_card_action*/
        margin: 2.5rem auto 0;
        width: 100%;
        height: 2.5rem
    }        
}

@media (min-width: 769px){
    .checkout_page{
        width: 100%;
        min-height: calc(100vh - var(--header-height));
        margin-top: var(--header-height);
        background-color: #EEF1F6;
        box-sizing: border-box;
        height: 100%;
        padding: 2.5rem 5rem;
    }
    
    .checkout_page_card{
        width: 20rem;
        margin: 0 auto;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 0 2.5rem #00000010;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem;
        box-sizing: border-box;
    }
    
    .checkout_page_card > h2{
        text-align: center;
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .checkout_item_name{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        padding: 1.5rem 2rem;
        background-color: #EEF1F6;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 0.75rem;
    }
    
    .checkout_item_name > img{
        height: 2.5rem;
        width: auto;
    }
    
    .checkout_item_name > p{
        text-align: start;
        font-size: 1.4rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        max-width: 60%;
    }
    
    .checkout_page_card_input_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 1rem 0 0;
    }
    
    .checkout_page_card_input_item > p{
        width: 100%;
        color: black;
        font-family: Josefinsans, sans-serif;
        text-align: start;
        margin: 1.5rem 0 0.5rem 0.25rem;
        font-size: 0.6rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .checkout_page_card_input_item > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: auto;
        transition: background-color 0.3s linear;
    }
    
    .checkout_page_card_input_item > input:focus{
        background-color: #E2E2E2;
    }
    
    .checkout_page_price{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 0.05rem solid black;
        margin: 1.5rem 0 0;
    }
    
    .checkout_page_price > p.label{
        color: black;
        font-family: Josefinsans, sans-serif;
        text-align: start;
        margin: 1rem 0 0;
        font-size: 0.6rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .checkout_page_price > p.value{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 1rem 0 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .checkout_page_card >  button.checkout_page_card_action{
        /*WIDTH HEIGHT AND MARGIN SAME AS .checkout_page_card_progress_container*/
        width: 100%;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 2.5rem auto 0;
    }
    
    .checkout_page_card_progress_container{
        /*WIDTH HEIGHT AND MARGIN SAME AS .checkout_page_card_action*/
        margin: 2.5rem auto 0;
        width: 100%;
        height: 2.5rem
    }        
}
