@media (max-width: 768px){

}

@media (min-width: 769px){
    .contact_page{
        --min-allowed-width: 50rem;
        width: 100%;
        height: auto;
        min-height: calc(100vh - var(--header-height));
        padding-top: var(--header-height);
        padding-bottom: 10rem;
        box-sizing: border-box;
    }
    
    .contact_page_card{
        width: 80%;
        height: auto;
        max-width: 60rem;
        min-height: 30rem;
        margin: 2rem auto;
        display: flex;
        flex-direction: row;
        padding: 2.5rem 0;
        box-sizing: border-box;
        border-radius: 2.5rem;
        box-shadow: 0 0 2.5rem #00000010;
        align-items: center;
    }
    
    .contact_page_card_section-1{
        height: auto;
        min-height: 100%;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    
    .contact_page_card_section-1 > h1{
        text-align: start;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        max-width: 20rem;
    }
    
    .contact_page_card_section-1_rows_container{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: space-evenly;
        width: calc(100% - 4rem);
        height: auto;
        max-width: 35rem;
        min-height: 20rem;
        margin: 0.5rem auto 1rem;
    }
    
    .contact_page_card_section-1_row_item{
        display: flex;
        flex-direction: column;
        width: 45%;
        margin: 1rem 0;
    }

    .contact_page_card_section-1_row_item.full_width{
        width: 100%;
    }

    
    .contact_page_card_section-1_row_item > p{
        width: 100%;
        color: black;
        font-family: Josefinsans, sans-serif;
        text-align: start;
        margin: 0 0 0.5rem 0.25rem;
        font-size: 0.7rem;
        font-weight: normal;
    }
    
    .contact_page_card_section-1_row_item > input, .contact_page_card_section-1_row_item > div{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: auto;
        transition: background-color 0.3s linear;
    }

    .contact_page_card_section-1_row_item  select{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
    }

    .contact_page_card_section-1_row_item  textarea{
        border-radius: 0.75rem;
        min-height: 10rem;
        outline: none;
        padding: 1rem;
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        border: 1px solid #00000040;
    }

    .contact_page_card_section-1_row_item  textarea:focus{
        border: 1px solid black;
    }

    .contact_page_card_section-1_row_item > div{
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .contact_page_card_section-1_row_item > input:focus, .contact_page_card_section-1_row_item > div:focus-within{
        background-color: #E2E2E2;
    }
    
    .contact_page_card_section-1 > button{
        /*MATCHES STYLES WITH contact_page_progress_container*/
        width: 15rem;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.7rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.75rem 0;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
    }
    
    .contact_page_progress_container{
        /*MATCHES STYLES WITH contact_page_card_section-1 > button*/
        width: 15rem;
        height: 2.5rem;
    }
    
    .contact_page_card_section-2{
        width: 40%;
        height: auto;
        display: flex;
        flex-direction: column;
        border-left: solid 0.05rem black;
        padding: 0 2rem;
        box-sizing: border-box;
    }
    
    .contact_page_card_section-2_item{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
    }
    
    .contact_page_card_section-2_item:first-child{
        margin: 0;
    }
    
    .contact_page_card_section-2_item > h2{
        text-align: start;
        font-size: 1.5rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 1rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .contact_page_card_section-2_item > h3{
        text-align: start;
        font-size: 1.3rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 1rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .contact_page_card_section-2_item > p{
        text-align: start;
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 0.25rem;
        font-weight: normal;
    }    
}

