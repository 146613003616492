@media (max-width: 768px){
    .footer{
        width: 100%;
        height: auto;
        background: #004E89;
        display: flex;
        flex-direction: column;
        padding: 3.5rem 10% 5rem;
        box-sizing: border-box;
        margin-top: 1px;
    }

    .footer_columns{
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;        
    }
    

    .footer_column{
        margin: 0 0 1rem;
        box-sizing: border-box;
        width: calc(50% - 1rem);
        height: auto;
        display: flex;
        flex-direction: column;
    }


    .footer_column > p.footer_column_title{
        text-align: start;
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: #F9FAFB;
        width: 100%;
        margin: 0 0 1.3rem;
    }

    .footer_column > a{
        text-align: start;
        font-size: 0.85rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        width: 100%;
        margin-bottom: 1rem;
        text-decoration: none;
    }
    
    .footer_socials{
        width: 100%;
        height: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .footer_logo-box_desktop{
        display: none;
    }

    .footer_logo-box_mobile{
        width: 1rem;
        height: 1rem;
        margin-right: 1rem;
        /* display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; */
    }

    .footer_logo-box_mobile img, .footer_logo-box_desktop img{
        width: 1rem;
        height: 1rem;
        object-fit: contain;
        object-position: center;
    }

    .footer_socials > a{
        height: 100%;
        background: none;
        outline: none;
        text-decoration: none;
        border: none;
        margin-right: 1rem;
    }

    .footer_socials > a:last-child{
        margin-right: 0;
    }

    .footer_socials > a > img{
        height: 1rem;
        width: 1rem;
        object-fit: contain;
        object-position: center;
    }
}

@media (min-width: 769px){
    .footer{
        width: 100%;
        height: auto;
        background: #004E89;
        display: flex;
        flex-direction: column;
        padding: 3.5rem 10% 5rem;
        box-sizing: border-box;
        margin-top: 1px;
    }

    .footer_columns{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;        
    }

    .footer_logo-box_mobile{
        display: none;
    }

    .footer_logo-box_desktop{
        width: auto;
        flex-grow: 1;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .footer_logo-box_desktop  img, .footer_logo-box_mobile  img{
        width: 2.5rem;
        height: 2.5rem;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
    }

    .footer_column{
        padding: 0 2rem;
        box-sizing: border-box;
        width: auto;
        flex-grow: 1;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .footer_column > p.footer_column_title{
        text-align: start;
        font-size: 1.25rem;
        font-family: Gibson-light, sans-serif;
        color: #F9FAFB;
        width: 100%;
        margin: 0 0 1.3rem;
    }

    .footer_column > a{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        width: 100%;
        margin-bottom: 1rem;
        text-decoration: none;
    }
    
    .footer_socials{
        width: 100%;
        height: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    .footer_socials > a{
        height: 100%;
        background: none;
        outline: none;
        text-decoration: none;
        border: none;
        margin-right: 2rem;
    }

    .footer_socials > a:last-child{
        margin: none;
    }

    .footer_socials > a > img{
        height: 1rem;
        width: 1rem;
        object-fit: contain;
        object-position: center;
    }
}
