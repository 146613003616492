@media (max-width: 768px){
    .test_page{
        width: 100%;
        min-height: 100vh;
        box-sizing: border-box;
    }
    
    .test_page_header{
        display: flex;
        flex-direction: row;
        height: var(--header-height);
        width: 100%;
        justify-content: space-between;
        padding: 0 2.5rem;
        box-sizing: border-box;
        align-items: center;
    }
    
    .test_page_header > img{
        width: auto;
        height: 50%;
    }

    .test_page_header > img.vcura{
        margin: auto 1.5rem auto auto;
    }
    
    .test_page_header > button{
        height: 1.5rem;
        width: auto;
        cursor: pointer;
        border: none;
        outline: none;
        background: none;
    }
    
    .test_page_header > button > img{
        width: auto;
        height: 100%;
    }

    .test_page_progress_container{
        width: 100%;
        height: 2.5rem;
        margin: 10rem 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .test_page_content{
        width: 100%;
        padding: 0 1.5rem;
        box-sizing: border-box;
    }
    
    
    /*-------------------------------------- onboarding section ------------------------------------*/
    
    .test_page_onboarding{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1.5rem 2rem 5rem;
        margin: 0 0 var(--header-height);
        background-color: #F7F9FB;
        border-radius: 1.5rem;
        box-sizing: border-box;
    }
    
    .test_page_onboarding > h2{
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 1.5rem auto;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_onboarding_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 1rem 0;
    }
    
    .test_page_onboarding_item > p{
        font-size: 1rem;
        text-align: start;
        width: 100%;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 auto 1.25rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
    }
    
    .test_page_onboarding_item > input, .test_page_onboarding_item > div{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: auto;
        transition: background-color 0.3s linear;
    }
    
    .test_page_onboarding_item > div{
        padding: 0 1rem;
        height: 2.45rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .test_page_onboarding > button{
        width: 75%;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 2.5rem auto 0;
        transition: border 0.2s ease, color 0.2s ease,background 0.2s ease,opacity 0.2s ease;
    }
    
    .test_page_onboarding > button.disabled{
        cursor: default;
        border: 0.1rem solid #024E85;
        color: #024E85;
        opacity: 0.6;
        background-color: transparent;
    }
    
    
    /*---------------------------instructions section -----------------------------*/
    .test_page_instructions{
        width: 100%;
        height: auto;
        background-color: #F7F9FB;
        border-radius: 1.5rem;
        margin: 0 auto var(--header-height);
        padding: 3rem 1.5rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    
    .test_page_instructions_main_title{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 2.5rem;
    }
    
    .test_page_instructions_main_title > img{
        height: 2.5rem;
        width: auto;
        margin: 0 1.5rem 0 0;
    }
    
    .test_page_instructions_main_title > h1{
        font-size: 2.2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_instructions > h3{
        font-size: 1rem;
        font-family: Josefinsans, sans-serif;
        text-align: start;
        color: #062D4A;
        margin: 0 0 1.5rem;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .test_page_instructions > p{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
        text-align: start;
    }
    
    .test_page_instructions > p.instruction_item{
        margin: 1.5rem 0 0;
    }
    
    .test_page_instruction_action{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 1.25rem 0 0;
    }
    
    .test_page_instruction_action > p{
        margin: 0 0 0 0.5rem;
        font-size: 0.85rem;
    }
    
    
    /*---------------------------------- test section ----------------------------------*/
    
    .test_page_test_section{
        width: 100%;
        max-width: 50rem;
        height: auto;
        margin: 0 auto var(--header-height);
        display: flex;
        flex-direction: column;
    }


    /* xxxxxxxxxxxxxxx language selection subsection xxxxxxxxxxxxxxxxxxxxxxxxxx */

    .test_page_language_section{
        width: 80%;
        height: auto;
        margin: 0 auto var(--header-height);
        display: flex;
        flex-direction: column;
    }

    .test_page_language_section > h2{
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 2rem 0;
        font-weight: normal;
        cursor: pointer;
    }

    .test_page_language_section_language_selection{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 2rem 0;
    }

    .test_page_language_section_language_selection > button{
        --profile_page_modal_button_size: calc((65vw - 3rem)/2);
        /* --profile_page_modal_button_size: 7rem; */
        width: var(--profile_page_modal_button_size);
        height: var(--profile_page_modal_button_size);
        min-width: 8rem;
        min-height: 8rem;
        background: #F7F9FB;
        border-radius: 1rem;
        border: 0.1rem solid white;
        text-align: center;
        outline: none;
        cursor: pointer;
        font-size: 1.3rem;
        font-family: Gibson-light, sans-serif;
        box-sizing: border-box;
        margin-bottom: 2rem;        
        transition: color 0.2s ease, border 0.2s ease;
    }

    .test_page_language_section_language_selection > button.selected{
        color: #024E85;
        border: 0.1rem solid #024E85;
    }

    .test_page_language_section_language_selection_action_button{
        text-decoration: none;
        width: 100%;
        max-width: 15rem;
        margin: auto;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.8rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        box-sizing: border-box;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
    }

    /* xxxxxxxxxxxxxxx END OF language selection subsection xxxxxxxxxxxxxxxxxxxxxxxxxx */
    
    .test_page_test_section  p{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 0.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
        text-align: center;
    }

    .test_page_test_section  p:nth-child(2){
        margin-bottom: 2rem;
    }
    
    .test_page_question_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        margin: 0;
    }
    
    .test_page_question_item > p.test_page_question_item_number{
        font-weight: normal;
        margin: 0 auto 0.75rem;
        text-align: center;
        width: 100%;
    }
    
    .test_page_question_item > h2{
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 auto 1.5rem;
        width: 100%;
        text-align: start;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_question_item_options_row{
        display: flex;
        flex-direction: row;
        height: auto;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .test_page_question_item_options_row_item{
        --question-option-width: calc((100vw - 8rem)/2);
        width: var(--question-option-width);
        margin-bottom: 2rem;
    }

    .test_page_question_item_options_row_item:nth-child(odd){
        margin-right: 2rem;
    }
    
    .test_page_question_item_options_row_item > p.test_page_question_item_subtitle{
        text-align: center;
        margin: 1rem 0;
        transition: color 0.2s ease;
    }
    
    .test_page_question_item_options_row_item > p.test_page_question_item_subtitle.selected{
        color: #75ADCC;
    }
    
    
    .test_page_question_item_options_row_item > button{
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
    }
    
    .test_page_question_item_options_row_item  svg{
        width: 100%;
        height: 100%;
    }
    
    .test_page_question_item_options_row_item svg *{
        transition: stroke 0.2s ease, fill 0.2s ease;
    }
    
    .test_page_test_section_dash{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #F7F9FB;
        border-radius: 2.5rem;
        padding: 2.5rem;
        margin: 2.5rem 0;
        box-sizing: border-box;
    }
    
    .test_page_test_section_summary{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .test_page_test_section_summary > img{
        width: auto;
        height: 2.5rem;
        object-fit: contain;
        object-position: center;
    }
    
    .test_page_test_section_summary > h2{
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 1.5rem auto;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_test_section_summary > p{
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 1.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
        text-align: start;
    }
    
    .test_page_test_section_summary > p.total{
        font-size: 2.3rem;
        letter-spacing: 0;
        font-weight: bold;
    }
    
    .test_page_test_section_summary > p.total > span{
        color: #75ADCC;
    }
    
    .test_page_test_section_dash > button{
        font-size: 1.1rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        padding: 0.75rem 2rem;
        background: white;
        outline: none;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
    }
    
    .test_page_test_section_dash > .questions_page_progress_container{
        height: 2.85rem;
        width: 7.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .test_page_test_section_dash > button:disabled{
        cursor: default;
        background: none;
    }
    
    
    /*-------------------------------------- Test page modal  -------------------------------------*/
    
    .test_page_close_dialog{
        width: 20rem;
        max-width: 100%;
        height: auto;
        padding: 2rem 2.5rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    
    .test_page_close_dialog > h2{
        font-size: 1.6rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 auto 2.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_close_dialog > p{
        font-size: 0.7rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
        text-align: center;
    }
    
    .test_page_close_dialog_buttons {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .test_page_close_dialog_buttons > button{
        width: calc(50% - 0.5rem);
        height: 2.5rem;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.6rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        border-radius: 1rem;
        outline: none;
        cursor: pointer;
    }
    
    .test_page_close_dialog_buttons > button.button_1{
        background-color: #024E85;
        color: white;
        border: 0.1rem solid white
    }
    
    .test_page_close_dialog_buttons > button.button_2{
        background-color: white;
        color: #024E85;
        border: 0.1rem solid #024E85;
    }    
}

@media (min-width: 769px){
    .test_page{
        width: 100%;
        min-height: 100vh;
        box-sizing: border-box;
    }
    
    .test_page_header{
        display: flex;
        flex-direction: row;
        height: var(--header-height);
        width: 100%;
        justify-content: space-between;
        padding: 0 2.5rem;
        box-sizing: border-box;
        align-items: center;
    }
    
    .test_page_header > img{
        width: auto;
        height: 50%;
    }

    .test_page_header > img.vcura{
        margin: auto 1.5rem auto auto;
    }
    
    .test_page_header > button{
        height: 1.5rem;
        width: auto;
        cursor: pointer;
        border: none;
        outline: none;
        background: none;
    }
    
    .test_page_header > button > img{
        width: auto;
        height: 100%;
    }

    .test_page_progress_container{
        width: 100%;
        height: 2.5rem;
        margin: 10rem 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .test_page_content{
        width: 100%;
        box-sizing: border-box;
    }
    
    
    /*-------------------------------------- onboarding section ------------------------------------*/
    
    .test_page_onboarding{
        width: 30%;
        min-width: 25rem;
        max-width: 50rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1.5rem 2.5rem 5rem;
        margin: 0 auto var(--header-height);
        background-color: #F7F9FB;
        border-radius: 1.5rem;
    }
    
    .test_page_onboarding > h2{
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 1.5rem auto;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_onboarding_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 1rem 0;
    }
    
    .test_page_onboarding_item > p{
        font-size: 1rem;
        text-align: start;
        width: 100%;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 auto 1.25rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
    }
    
    .test_page_onboarding_item > input, .test_page_onboarding_item > div{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0;
        border: none;
        outline: none;
        width: 100%;
        height: auto;
        transition: background-color 0.3s linear;
    }
    
    .test_page_onboarding_item > div{
        padding: 0 1rem;
        height: 2.45rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .test_page_onboarding > button{
        width: 75%;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.65rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 2.5rem auto 0;
        transition: border 0.2s ease, color 0.2s ease,background 0.2s ease,opacity 0.2s ease;
    }
    
    .test_page_onboarding > button.disabled{
        cursor: default;
        border: 0.1rem solid #024E85;
        color: #024E85;
        opacity: 0.6;
        background-color: transparent;
    }
    
    
    /*---------------------------instructions section -----------------------------*/
    .test_page_instructions{
        width: 60%;
        height: auto;
        min-width: 30rem;
        max-width: 60rem;
        background-color: #F7F9FB;
        border-radius: 1.5rem;
        margin: 0 auto var(--header-height);
        padding: 3rem 4rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    
    .test_page_instructions_main_title{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 2.5rem;
    }
    
    .test_page_instructions_main_title > img{
        height: 2.5rem;
        width: auto;
        margin: 0 1.5rem 0 0;
    }
    
    .test_page_instructions_main_title > h1{
        font-size: 2.2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_instructions > h3{
        font-size: 1rem;
        font-family: Josefinsans, sans-serif;
        text-align: start;
        color: #062D4A;
        margin: 0 0 1.5rem;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .test_page_instructions > p{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0;
        font-weight: bold;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
        text-align: start;
    }
    
    .test_page_instructions > p.instruction_item{
        margin: 1.5rem 0 0;
    }
    
    .test_page_instruction_action{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 1.25rem 0 0;
    }
    
    .test_page_instruction_action > p{
        margin: 0 0 0 0.5rem;
    }
    
    
    /*---------------------------------- test section ----------------------------------*/
    
    .test_page_test_section{
        width: 60%;
        max-width: 50rem;
        height: auto;
        margin: 0 auto var(--header-height);
        display: flex;
        flex-direction: column;
    }

    /* xxxxxxxxxxxxxxx language selection subsection xxxxxxxxxxxxxxxxxxxxxxxxxx */

    .test_page_language_section{
        width: 80%;
        height: auto;
        margin: 0 auto var(--header-height);
        display: flex;
        flex-direction: column;
    }

    .test_page_language_section > h2{
        font-size: 1.7rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0;
        font-weight: normal;
        cursor: pointer;
    }

    .test_page_language_section_language_selection{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 2rem 0;
    }

    .test_page_language_section_language_selection > button{
        --profile_page_modal_button_size: calc((80vw - 32px - 12rem)/4);
        /* --profile_page_modal_button_size: 7rem; */
        width: var(--profile_page_modal_button_size);
        height: var(--profile_page_modal_button_size);
        min-width: 7rem;
        min-height: 7rem;
        background: #F7F9FB;
        border-radius: 1rem;
        border: 0.1rem solid white;
        text-align: center;
        outline: none;
        cursor: pointer;
        font-size: 1.3rem;
        font-family: Gibson-light, sans-serif;
        box-sizing: border-box;
        margin-bottom: 2rem;
        transition: color 0.2s ease, border 0.2s ease;
    }

    .test_page_language_section_language_selection > button.selected{
        color: #024E85;
        border: 0.1rem solid #024E85;
    }

    .test_page_language_section_language_selection_action_button{
        text-decoration: none;
        width: 100%;
        max-width: 15rem;
        margin: auto;
        height: 2.5rem;
        color: white;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.8rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        box-sizing: border-box;
        background-color: #024E85;
        border-radius: 1rem;
        border: none;
        outline: none;
        cursor: pointer;
    }

    /* xxxxxxxxxxxxxxx END OF language selection subsection xxxxxxxxxxxxxxxxxxxxxxxxxx */
    
    .test_page_test_section  p{
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 0.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
        text-align: start;
    }
    
    .test_page_question_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        margin: 1.5rem 0;
    }
    
    .test_page_question_item > p.test_page_question_item_number{
        font-weight: normal;
        margin: 0 0 0.75rem 0;
    }
    
    .test_page_question_item > h2{
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 auto 1.5rem;
        width: 100%;
        text-align: start;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_question_item_options_row{
        display: flex;
        flex-direction: row;
        height: auto;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    
    .test_page_question_item_options_row_item > p.test_page_question_item_subtitle{
        text-align: center;
        margin: 1rem 0;
        transition: color 0.2s ease;
    }
    
    .test_page_question_item_options_row_item > p.test_page_question_item_subtitle.selected{
        color: #75ADCC;
    }
    
    
    .test_page_question_item_options_row_item > button{
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
    }
    
    .test_page_question_item_options_row_item  svg{
        width: 100%;
        height: 100%;
    }
    
    .test_page_question_item_options_row_item svg *{
        transition: stroke 0.2s ease, fill 0.2s ease;
    }
    
    .test_page_test_section_dash{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #F7F9FB;
        border-radius: 2.5rem;
        padding: 2.5rem;
        margin: 2.5rem 0;
        box-sizing: border-box;
    }
    
    .test_page_test_section_summary{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .test_page_test_section_summary > img{
        width: auto;
        height: 2.5rem;
        object-fit: contain;
        object-position: center;
    }
    
    .test_page_test_section_summary > h2{
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 1.5rem auto;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_test_section_summary > p{
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 1.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
        text-align: start;
    }
    
    .test_page_test_section_summary > p.total{
        font-size: 2.3rem;
        letter-spacing: 0;
        font-weight: bold;
    }
    
    .test_page_test_section_summary > p.total > span{
        color: #75ADCC;
    }
    
    .test_page_test_section_dash > button{
        font-size: 1.1rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        padding: 0.75rem 2rem;
        background: white;
        outline: none;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
    }
    
    .test_page_test_section_dash > .questions_page_progress_container{
        height: 2.85rem;
        width: 7.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .test_page_test_section_dash > button:disabled{
        cursor: default;
        background: none;
    }
    
    
    /*-------------------------------------- Test page modal  -------------------------------------*/
    
    .test_page_close_dialog{
        width: 32.5rem;
        height: auto;
        padding: 2rem 2.5rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    
    .test_page_close_dialog > h2{
        font-size: 1.9rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 auto 2.5rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
    }
    
    .test_page_close_dialog > p{
        font-size: 0.9rem;
        font-family: Gibson-light, sans-serif;
        color: #062D4A;
        margin: 0 0 1.5rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
        line-height: 1.2;
        width: 100%;
        text-align: center;
    }
    
    .test_page_close_dialog_buttons {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .test_page_close_dialog_buttons > button{
        width: calc(50% - 0.5rem);
        height: 2.5rem;
        font-family: Josefinsans, sans-serif;
        text-align: center;
        font-size: 0.8rem;
        letter-spacing: 0.05rem;
        font-weight: normal;
        padding: 0.85rem 0;
        border-radius: 1rem;
        outline: none;
        cursor: pointer;
    }
    
    .test_page_close_dialog_buttons > button.button_1{
        background-color: #024E85;
        color: white;
        border: 0.1rem solid white
    }
    
    .test_page_close_dialog_buttons > button.button_2{
        background-color: white;
        color: #024E85;
        border: 0.1rem solid #024E85;
    }    
}
