@media (max-width: 768px){
  html {
    font-size: 16px;
  }
}

@media (min-width: 769px) and (max-width: 1280px)  {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1281px){
  html {
    font-size: 20px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}


@font-face {
  font-family: 'Gibson';
  src: local('Gibson'), url(Fonts/Gibson/GibsonRegular.OTF) format('opentype');
}

@font-face {
  font-family: 'Gibson-light';
  src: local('Gibson-light'), url(Fonts/Gibson/GibsonLight.OTF) format('opentype');
}

@font-face {
  font-family: 'Josefinsans-semibold';
  src: local('Josefinsans-semibold'), url(Fonts/JosefinSans/JosefinSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Josefinsans';
  src: local('Josefinsans'), url(Fonts/JosefinSans/JosefinSans-Regular.ttf) format('truetype');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
