@media (max-width: 768px){
    .browse_blogs_page{
        width: 100%;
        height: auto;
        margin: var(--header-height) 0 0;
        box-sizing: border-box;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
    }
    
    .browse_blogs_page_title{
        width: 100%;
        height: auto;    
        border-bottom: 0.05rem solid #E6E6E6;
        padding: 0 0 2rem;
    }
    
    .browse_blogs_page_title_content{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    
    .browse_blogs_page_title_content > h2{
        text-align: start;
        font-size: 2.5rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        margin: 1rem 0;
        font-weight: bolc;
        letter-spacing: 0.05rem;
        width: 100%;
    }
    
    .browse_blogs_page_title_content > p{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #808080;
        margin: 0.25rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
        width: 100%;
    }
    
    .browse_blogs_page_list{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;    
        overflow-y: auto;    
    }   
    
    .browse_blogs_page_list > .blog_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0;
        border-bottom: 0.05rem solid #E6E6E6;
    }
    
    .browse_blogs_page_list > .blog_item:last-child{
        border-bottom: none;
    }
    
    .browse_blogs_page_list > .blog_item > img{
        width: 100%;
        height: auto;
        max-height: 14rem;
        object-fit: cover;
        object-position: center;
    }
    
    .blog_item > .blog_item_content{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    
    .blog_item > .blog_item_content > h2{
        text-align: start;
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        margin: 1rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .blog_item > .blog_item_content > p{
        text-align: start;
        font-size: 0.7rem;
        font-family: Gibson-light, sans-serif;
        color: #808080;
        margin: 1rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }
    
    .blog_item > .blog_item_content > a{
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 1rem;
        text-align: center;
        width: 7rem;
        border-radius: 0.75rem;
        background-color: #1E2432;
        text-decoration: none;
        outline: none;
        border: none;
        cursor: pointer;
        align-self: flex-end;
    }    
}



@media (min-width: 769px){
    .browse_blogs_page{
        width: 100%;
        min-height:  calc(33.4rem - var(--header-height));
        height: calc(100vh - var(--header-height));
        margin: var(--header-height) 0 0;
        box-sizing: border-box;
        padding: 1.5rem 2rem 1.5rem 2rem;
        display: flex;
        flex-direction: row;
    }
    
    .browse_blogs_page_title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 2rem;
        box-sizing: border-box;
        flex-grow: 1;
        max-width: 40%;
        height: 100%;
    }
    
    .browse_blogs_page_title_content{
        width: 100%;
        height: 15rem;
        border-right: 0.05rem solid #E6E6E6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .browse_blogs_page_title_content > h2{
        text-align: start;
        font-size: 2.5rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        margin: 0.25rem 0 1rem;
        font-weight: bold;
        letter-spacing: 0.05rem;
        width: 10rem;
    }
    
    .browse_blogs_page_title_content > p{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #808080;
        margin: 0.25rem 0;
        font-weight: normal;
        letter-spacing: 0.05rem;
        width: 10rem;
    }
    
    .browse_blogs_page_list{
        width: 60%;
        min-width: 30rem;
        height: 100%;
        display: flex;
        flex-direction: column;    
        box-sizing: border-box;
        padding: 0 3rem 0 1rem;
        overflow-y: auto;    
    }   
    
    .browse_blogs_page_list > .blog_item{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 11rem;    
        padding: 1rem 0;
        border-bottom: 0.05rem solid #E6E6E6;
    }
    
    .browse_blogs_page_list > .blog_item:last-child{
        border-bottom: none;
    }
    
    .browse_blogs_page_list > .blog_item > img{
        width: 40%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    
    .blog_item > .blog_item_content{
        width: 60%;
        height: 100%;
        padding: 1rem 1rem 1rem 2rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    
    .blog_item > .blog_item_content > h2{
        text-align: start;
        font-size: 1.2rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        margin: 0 0 0.25rem;
        font-weight: normal;
    }
    
    .blog_item > .blog_item_content > p{
        text-align: start;
        font-size: 0.8rem;
        font-family: Gibson-light, sans-serif;
        color: #808080;
        margin: 0.25rem 0;
        font-weight: normal;
    }
    
    .blog_item > .blog_item_content > a{
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 1rem;
        text-align: center;
        width: 7rem;
        border-radius: 0.75rem;
        background-color: #1E2432;
        text-decoration: none;
        outline: none;
        border: none;
        cursor: pointer;
    }    
}
