@media (max-width: 768px){
    .privacy_policy_page{
        width: 100%;
        height: auto;
        margin: var(--header-height) 0 0;
        box-sizing: border-box;
        padding: 1.5rem 2rem 10rem;
        display: flex;
        flex-direction: column;
    }

    .privacy_policy_page > h1{
        font-size: 2rem;
        font-family: Gibson, sans-serif;
        color: #1E2432;
        text-align: center;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        margin: 4rem auto 2rem;
    }

    .privacy_policy_page > h2{
        font-size: 1.8rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        text-align: start;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        margin: 1.8rem auto 0.8rem;
    }

    .privacy_policy_page > h3{
        font-size: 1.5rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        text-align: start;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        margin: 1.7rem auto 0.8rem;
    }

    .privacy_policy_page > p, .privacy_policy_page li{
        font-size: 0.85rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        text-align: start;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        margin: 1.3rem auto 0.3rem;
        line-height: 1.2;
    }
}

@media (min-width: 769px){
    .privacy_policy_page{
        width: 100%;
        height: auto;
        margin: var(--header-height) 0 0;
        box-sizing: border-box;
        padding: 1.5rem 10% 10rem;
        display: flex;
        flex-direction: column;
    }

    .privacy_policy_page > h1{
        font-size: 2.3rem;
        font-family: Gibson, sans-serif;
        color: #1E2432;
        text-align: center;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        margin: 4rem auto;
    }

    .privacy_policy_page > h2{
        font-size: 1.8rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        text-align: start;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        margin: 2.4rem auto 1rem;
    }

    .privacy_policy_page > h3{
        font-size: 1.5rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        text-align: start;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        margin: 2rem auto 0.8rem;
    }

    .privacy_policy_page > p, .privacy_policy_page li{
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        text-align: start;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0.05rem;
        margin: 1.8rem auto 0.3rem;
        line-height: 1.3;
    }
}