@media (max-width: 768px){
    .mobile_nav{
        display: flex;
        flex-direction: column;
        width: 100%;
        top: 0;
        left: 0;
        position: fixed;    
        background-color: white;
        z-index: 300;
        padding: 1rem 3rem 3rem;
        height: auto;
        min-height: 100vh;
        align-items: flex-start;
        box-sizing: border-box;
    }

    .mobile_nav_header{
        height: var(--header-height);
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .mobile_nav_header > img{
        height: 3rem;
        width: 3rem;
        cursor: pointer;
        display: block;
        object-fit: contain;
        object-position: center;
    }

    .mobile_nav_header > button.cross_button{
        height: 2rem;
        width: 2rem;
        cursor: pointer;
        background: transparent url("../Assets/TestIcons/cross-button-icon.png") no-repeat center;
        background-size: contain;
        display: block;
        outline: none;
        border: none;
        margin-left: auto;
    }

    .mobile_nav > p.mobile_nav_title{
        text-align: start;
        font-size: 1.3rem;
        font-family: Gibson-light, sans-serif;
        color: #808080;
        margin: 2rem 0 0;
        letter-spacing: 0.05rem;
    }

    .mobile_nav > .mobile_nav_main_links{
        display: flex;
        flex-direction: column;
        margin: 2rem 0;
        width: 100%;
        align-items: flex-start;
    }

    .mobile_nav > .mobile_nav_main_links > a, .mobile_nav > .mobile_nav_main_links > button{
        text-align: start;
        font-size: 1.8rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0 0 0.8rem;
        letter-spacing: 0.1rem;
        font-weight: bold;
        text-decoration: none;
        outline: none;
        border: none;
        background-color: transparent;
        padding: 0;
    }

    .mobile_nav > .mobile_nav_main_links > a:focus, .mobile_nav > .mobile_nav_main_links > button:focus{
        text-decoration: underline;
    }

    .mobile_nav > .mobile_nav_side_links{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mobile_nav > .mobile_nav_side_links > a.uems_link{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #363636;
        letter-spacing: 0.05rem;
        text-decoration: underline;
        margin-bottom: 1rem;
    }

}

@media (min-width: 769px){
    .mobile_nav{
        display: none;
    }
}