@media (max-width: 768px){
    .report_page{
        --min-allowed-width: 1200px;
        --max-allowed-width: 1280px;
        width: 100%;
        height: auto;
        position: relative;
        padding: var(--header-height) 0 0;
        box-sizing: border-box;
    }

    .report_page_top_row{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .report_page_top_row > h2{
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 10rem 0 3rem;
        font-weight: bold;
        letter-spacing: 0.2rem;
        max-width: 20rem;
    }

    .report_page_top_row > h2 > span{
        color: #004E89;
    }

    .report_page_top_row > img{
        width: 80%;
        height: auto;
        margin: 0 auto 5rem;
    }


    /*-------------------------- Report page items section -------------------------*/

    .report_page_item_blue, .report_page_item_red{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 3.75rem 0;
    }

    .report_page_item_blue > .report_page_item_imagebox{
        background-color: #8AC0DE;
    }

    .report_page_item_blue > .report_page_item_contentbox{
        background-color: #004E89;
    }

    .report_page_item_red > .report_page_item_imagebox{
        background-color: #FEB68E;
    }

    .report_page_item_red > .report_page_item_contentbox{
        background-color: #BC4830;
    }

    .report_page_item_imagebox{
        width: 100%;
        height: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .report_page_item_blue > .report_page_item_imagebox > img{
        width: auto;
        max-width: 100%;
        height: 90%;
        object-position: center;
        object-fit: contain;
    }

    .report_page_item_red > .report_page_item_imagebox > img{
        width: auto;
        height: 90%;
        object-fit: contain;
        object-position: -100% top;
        position: relative;
        left: -50%;
    }

    .report_page_item_contentbox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 3rem 1rem;
        box-sizing: border-box;
        justify-content: flex-end;
        align-items: center;
    }

    .report_page_item_contentbox > p.report_page_item_numbered-title{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 0 0 auto;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .report_page_item_contentbox > h2{
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        margin: 5rem 0 1rem;
        font-weight: bold;
        letter-spacing: 0.2rem;
        line-height: 1.4;
        width: 100%;
    }

    .report_page_item_contentbox > p.report_page_item_text{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        margin: 0 0 5rem;
        font-size: 1rem;
        letter-spacing: 1px;
        font-weight: bold;
        line-height: 1.5;
    }

    /*---------------------hover rise button section ------------------------*/

    .report_eval_feature_info, .report_sample_download_info{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }

    /*.report_eval_feature_info > div:nth-child(even), .report_sample_download_info > div:nth-child(even){*/
    /*    background-color: #F4F4F4;*/
    /*}*/

    /*.report_eval_feature_info > div:nth-child(even):hover, .report_sample_download_info > div:nth-child(even):hover{*/
    /*    background-color: white;*/
    /*}*/

    /*.report_eval_feature_info > div:nth-child(even) a, .report_sample_download_info > div:nth-child(even) a{*/
    /*    background-color: white;*/
    /*}*/

    /*.report_eval_feature_info > div:nth-child(even):hover a, .report_sample_download_info > div:nth-child(even):hover a{*/
    /*    background-color: #F4F4F4;*/
    /*}*/

    /*.report_eval_feature_info > div:nth-child(odd) a, .report_sample_download_info > div:nth-child(odd) a{*/
    /*    background-color: #F4F4F4;*/
    /*}*/

    /*.report_eval_feature_info > div:hover p.report_eval_feature_info_item_title, .report_sample_download_info > div:hover p.report_sample_download_info_item_title{*/
    /*    transform: scale(0.83) translateX(-9%);*/
    /*}*/

    /*.report_eval_feature_info > div:hover p.report_eval_feature_info_item_text, .report_sample_download_info > div:hover p.report_sample_download_info_item_text{*/
    /*    opacity: 1;*/
    /*    transform: scale(1);*/
    /*}*/

    .report_eval_feature_info_textbox_desktop, .report_sample_download_info_textbox_desktop{
        display: none;
    }

    .report_eval_feature_info_textbox_mobile, .report_sample_download_info_textbox_mobile{
        width: 100%;
        box-sizing: border-box;
        padding: 0 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 4rem 0;
    }

    .report_eval_feature_info_textbox_mobile > h2, .report_sample_download_info_textbox_mobile > h2{
        text-align: center;
        font-size: 2.5rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .report_eval_feature_info_textbox_mobile > p, .report_sample_download_info_textbox_mobile > p{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        margin: 1.5rem 0 0;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .report_eval_feature_info_item, .report_sample_download_info_item{
        min-width: 20rem;
        height: 20rem;
        min-height: 19rem;
        display: flex;
        flex-direction: column;
        padding: 2.5rem;
        box-sizing: border-box;
        position: relative;
        background-color: #F4F4F4;
        margin: 4rem 1rem;
    }

    /*.report_eval_feature_info_item:hover, .report_sample_download_info_item:hover{*/
    /*    background-color: white;*/
    /*    transform: scale(1.2);*/
    /*}*/

    .report_eval_feature_info_item > img, .report_sample_download_info_item > img{
        width: 2.5rem;
        height: 2.5rem;
        align-self: flex-start;
        object-position: center;
        object-fit: contain;
    }

    .report_eval_feature_info_item > p.report_eval_feature_info_item_title, .report_sample_download_info_item > p.report_sample_download_info_item_title{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        width: 60%;
        text-align: start;
        margin: 1.5rem 0 0;
        font-size: 1.6rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        transition: all 0.1s ease;
    }

    /*.report_eval_feature_info_item > p.report_eval_feature_info_item_text, .report_sample_download_info_item > p.report_sample_download_info_item_text{*/
    /*    color: #596177;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    text-align: start;*/
    /*    font-size: 14px;*/
    /*    opacity: 0;*/
    /*    transform: scale(0);*/
    /*    margin: 15px 0 0;*/
    /*    transition: opacity 0.1s ease, transform 0.1s ease;*/
    /*}*/

    .report_eval_feature_info_item > .report_eval_feature_info_item_action-button{
        width: 2rem;
        height: 2rem;
        max-width: 2.25rem;
        max-height: 2.25rem;
        align-self: flex-end;
        border-radius: 1.25rem;
        background-image: url("../Assets/round-transparent-bg-arrow.png");
        background-color: white;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg);
        cursor: pointer;
        margin-top: auto;
    }

    .report_sample_download_info_item > .report_sample_download_info_item_action-button{
        background-color: white;
        background-image: url("../Assets/download-icon.png");
        background-size: 40% 40%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        margin-top: auto;
        width: 2rem;
        height: 2rem;
        max-width: 2.25rem;
        max-height: 2.25rem;
        align-self: flex-end;
        border-radius: 1.25rem;
        border: none;
        outline: none;
    }


    /*----------------------Setup account section-----------------------*/

    .report_page_setup_account_section {
        height: 15rem;
        width: 100%;
        background: #F9FAFB;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .report_page_setup_account_section > h2{
        text-align: center;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        letter-spacing: 0.1rem;
        margin: 0;
        width: 25rem;
    }

    .report_page_setup_account_section > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 2rem 1rem 0;
        border: none;
        outline: none;
        width: 15rem;
        height: auto;
        transition: background-color 0.3s linear;
    }

    .report_page_setup_account_section > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .report_page_setup_account_section > input:focus{
        background-color: #E2E2E2;
    }

    .report_page_setup_account_section > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        cursor: pointer;
        margin: 1rem 0 0;
    }

    .report_page_setup_account_section > .report_page_progress_container {
        width: 7rem;
        height: 2.25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem 0 0;
    }
}

@media (min-width: 769px){
    .report_page{
        --min-allowed-width: 1200px;
        --max-allowed-width: 1280px;
        width: 100%;
        min-width: var(--min-allowed-width);
        height: auto;
        position: relative;
        padding: var(--header-height) 0 0;
        box-sizing: border-box;
    }

    .report_page_top_row{
        height: calc(100vh - var(--header-height));
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .report_page_top_row > h2{
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        max-width: 20rem;
    }

    .report_page_top_row > h2 > span{
        color: #004E89;
    }

    .report_page_top_row > img{
        height: 75%;
        width: auto;
    }

    /*-------------------------- Report page items section -------------------------*/

    .report_page_item_blue, .report_page_item_red{
        width: 100%;
        height: 60vw;
        max-height: calc(0.6* var(--max-allowed-width));
        min-height: calc(0.6* var(--min-allowed-width));
        display: flex;
        flex-direction: row;
        margin: 3.75rem auto 0;
    }

    .report_page_item_blue > .report_page_item_imagebox{
        background-color: #8AC0DE;
    }

    .report_page_item_blue > .report_page_item_contentbox{
        background-color: #004E89;
    }

    .report_page_item_red > .report_page_item_imagebox{
        background-color: #FEB68E;
    }

    .report_page_item_red > .report_page_item_contentbox{
        background-color: #BC4830;
    }

    .report_page_item_imagebox{
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .report_page_item_blue > .report_page_item_imagebox > img{
        width: auto;
        height: 90%;
        object-position: center;
        object-fit: contain;
    }

    .report_page_item_red > .report_page_item_imagebox > img{
        width: auto;
        height: 90%;
        object-fit: contain;
        object-position: -100% top;
        position: relative;
        left: -50%;
    }

    .report_page_item_contentbox {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 3rem 4rem;
        box-sizing: border-box;
        justify-content: center;
    }

    .report_page_item_contentbox > p.report_page_item_numbered-title{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 0 0 auto;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .report_page_item_contentbox > h2{
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        margin: 2rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        line-height: 1.4;
        width: 100%;
    }

    .report_page_item_contentbox > p.report_page_item_text{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 2rem 0;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    /*---------------------hover rise button section ------------------------*/

    .report_eval_feature_info, .report_sample_download_info{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 0 0 4rem;
    }

    .report_eval_feature_info > div:nth-child(even), .report_sample_download_info > div:nth-child(even){
        background-color: #F4F4F4;
    }

    /*.report_eval_feature_info > div:nth-child(even):hover, .report_sample_download_info > div:nth-child(even):hover{*/
    /*    background-color: white;*/
    /*}*/

    .report_eval_feature_info > div:nth-child(even) button, .report_sample_download_info > div:nth-child(even) button{
        background-color: white;
    }

    /*.report_eval_feature_info > div:nth-child(even):hover a, .report_sample_download_info > div:nth-child(even):hover a{*/
    /*    background-color: #F4F4F4;*/
    /*}*/

    .report_eval_feature_info > div:nth-child(odd) a, .report_sample_download_info > div:nth-child(odd) a{
        background-color: #F4F4F4;
    }

    /*.report_eval_feature_info > div:hover p.report_eval_feature_info_item_title, .report_sample_download_info > div:hover p.report_sample_download_info_item_title{*/
    /*    transform: scale(0.83) translateX(-9%);*/
    /*}*/

    /*.report_eval_feature_info > div:hover p.report_eval_feature_info_item_text, .report_sample_download_info > div:hover p.report_sample_download_info_item_text{*/
    /*    opacity: 1;*/
    /*    transform: scale(1);*/
    /*}*/

    .report_eval_feature_info_textbox_mobile, .report_sample_download_info_textbox_mobile{
        display: none;
    }

    .report_eval_feature_info_textbox_desktop, .report_sample_download_info_textbox_desktop{
        width: 50%;
        height: 25vw;
        min-height: calc(var(--min-allowed-width)*0.25);
        box-sizing: border-box;
        padding: 0 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .report_eval_feature_info_textbox_desktop > h2, .report_sample_download_info_textbox_desktop > h2{
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
    }

    .report_eval_feature_info_textbox_desktop > p, .report_sample_download_info_textbox_desktop > p{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 1.5rem 0 0;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
    }

    .report_eval_feature_info_item, .report_sample_download_info_item{
        width: 25%;
        height: 25vw;
        min-height: calc(var(--min-allowed-width)*0.25);
        display: flex;
        flex-direction: column;
        padding: 2.5rem;
        box-sizing: border-box;
        position: relative;
        transition: all 0.1s ease;
    }

    /*.report_eval_feature_info_item:hover, .report_sample_download_info_item:hover{*/
    /*    background-color: white;*/
    /*    transform: scale(1.2);*/
    /*}*/

    .report_eval_feature_info_item > img, .report_sample_download_info_item > img{
        width: 2.5rem;
        height: 2.5rem;
        align-self: flex-start;
        object-position: center;
        object-fit: contain;
    }

    .report_eval_feature_info_item > p.report_eval_feature_info_item_title, .report_sample_download_info_item > p.report_sample_download_info_item_title{
        color: #596177;
        font-family: Gibson-light, sans-serif;
        width: 100%;
        text-align: start;
        margin: 1.5rem 0 0;
        font-size: 1.3rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.2;
        transition: all 0.1s ease;
    }

    /*.report_eval_feature_info_item > p.report_eval_feature_info_item_text, .report_sample_download_info_item > p.report_sample_download_info_item_text{*/
    /*    color: #596177;*/
    /*    font-family: Gibson-light, sans-serif;*/
    /*    text-align: start;*/
    /*    font-size: 14px;*/
    /*    opacity: 0;*/
    /*    transform: scale(0);*/
    /*    margin: 15px 0 0;*/
    /*    transition: opacity 0.1s ease, transform 0.1s ease;*/
    /*}*/

    .report_eval_feature_info_item > .report_eval_feature_info_item_action-button{
        width: 25%;
        height: 25%;
        max-width: 2.25rem;
        max-height: 2.25rem;
        align-self: flex-end;
        border-radius: 1.25rem;
        background-image: url("../Assets/round-transparent-bg-arrow.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg);
        cursor: pointer;
        margin-top: auto;
    }

    .report_sample_download_info_item > .report_sample_download_info_item_action-button{
        width: 25%;
        height: 25%;
        max-width: 2.25rem;
        max-height: 2.25rem;
        align-self: flex-end;
        border-radius: 1.25rem;
        background-image: url("../Assets/download-icon.png");
        background-size: 40% 40%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        margin-top: auto;
        border: none;
        outline: none;
    }


    /*----------------------Setup account section-----------------------*/

    .report_page_setup_account_section {
        height: 20rem;
        width: 100%;
        background: #F9FAFB;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 4rem 0 0;
    }

    .report_page_setup_account_section > h2{
        text-align: start;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        letter-spacing: 0.1rem;
        margin: 0;
        width: 25rem;
        text-decoration: underline;
    }

    .report_page_setup_account_section > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0 1rem;
        border: none;
        outline: none;
        width: 15rem;
        height: auto;
        transition: background-color 0.3s linear;
    }

    .report_page_setup_account_section > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .report_page_setup_account_section > input:focus{
        background-color: #E2E2E2;
    }

    .report_page_setup_account_section > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        text-align: center;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        cursor: pointer;
    }

    .report_page_setup_account_section > .report_page_progress_container {
        width: 7rem;
        height: 2.25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
