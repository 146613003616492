@media (max-width: 768px) {
    .about_page{
        width: 100%;
        height: auto;
        position: relative;
        box-sizing: border-box;
        padding-top: var(--header-height);
    }

    .about_page span{
        color: #004E89;
    }

    .about_page_top{        
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    h2.about_page_top_title_mobile{
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
        margin: 0 auto 4rem;        
    }

    .about_page_top_content{
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;
        justify-content: space-evenly;
        align-items: center;
    }

    .about_page_top_content > h2{
        display: none;
    }

    .about_page_top_content > p{
        color: #3B404E;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        width: 100%;
        font-size: 0.9rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .about_page_top_content > a{
        width: auto;
        height: 4rem;
        cursor: pointer;
        outline: none;
        border: none;
    }

    .about_page_top_content > a > img{
        height: 100%;
        width: auto;
        object-fit: contain;
        object-position: center;
    }

    .about_page_top > img{
        width: 100%;
        height: auto;
        max-height: 20rem;
        object-position: right center;
        object-fit: cover;
    }

    .about_page_middle_section{
        height: auto;
        background-color: #F7F9FB;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        box-sizing: border-box;
    }

    .about_page_middle_section > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
    }

    .about_page_middle_section > p{
        color: #3B404E;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        max-width: 35rem;
        width: 100%;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
        margin: 2rem 0
    }

    .about_page_mission_section{
        height: auto;        
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        box-sizing: border-box;
        margin: 2rem 0;
    }

    .about_page_mission_item{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 25rem;
    }

    .about_page_mission_item > h2{
        text-align: center;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
        margin: 2rem 0;
    }

    .about_page_mission_item > p{
        color: #3B404E;
        font-family: Gibson-light, sans-serif;
        margin: 0;
        text-align: center;
        max-width: 35rem;
        width: 100%;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    h2.about_page_associates_title{
        text-align: center;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
        margin: 2rem 0 2rem;
    }

    .about_page_associate_container{
        margin: 5rem auto;
        overflow: hidden;
        position: relative;
        max-width: 100vw
    }

    img.about_page_associate_item{
        position: absolute;
        height: 100%;
        width: auto;
        object-fit: contain;
    }



    /*----------------------Setup account section-----------------------*/
    .about_page_setup_account_section {
        height: 20rem;
        width: 100%;
        background: #F9FAFB;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 4rem 0 0;
    }

    .about_page_setup_account_section > h2{
        text-align: start;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        letter-spacing: 0.1rem;
        margin: 0;
        width: 25rem;
        text-decoration: underline;
    }

    .about_page_setup_account_section > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0 1rem;
        border: none;
        outline: none;
        width: 15rem;
        height: auto;
        transition: background-color 0.3s linear;
    }

    .about_page_setup_account_section > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .about_page_setup_account_section > input:focus{
        background-color: #E2E2E2;
    }

    .about_page_setup_account_section > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        text-align: center;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        cursor: pointer;
    }

    .about_page_setup_account_section > .about_page_progress_container {
        width: 7rem;
        height: 2.25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}



@media (min-width: 769px) {
    .about_page{
        width: 100%;
        /* min-width: var(--min-allowed-width); */
        height: auto;
        position: relative;
        box-sizing: border-box;
    }

    .about_page span{
        color: #004E89;
    }

    .about_page_top{
        height: auto;
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* align-items: center; */
    }

    h2.about_page_top_title_mobile{
        display: none;
    }

    .about_page_top_content{
        box-sizing: border-box;
        width: 50%;
        height: auto;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        padding: var(--header-height) 5rem 5rem;
        justify-content: space-evenly;
        align-items: center;
    }

    .about_page_top_content > h2{
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
        margin-bottom: 2rem;
    }

    .about_page_top_content > p{
        color: #3B404E;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        max-width: 35rem;
        width: 100%;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .about_page_top_content > a{
        width: auto;
        height: 4rem;
        cursor: pointer;
        outline: none;
        border: none;
    }

    .about_page_top_content > a > img{
        height: 100%;
        width: auto;
        object-fit: contain;
        object-position: center;
    }

    .about_page_top > img{
        min-height: 100%;
        /* min-height: 100%; */
        max-height: 60rem;
        max-width: 50%;
        width: auto;        
        object-position: center center;
        object-fit: cover;
    }

    .about_page_middle_section{
        height: 35rem;
        background-color: #F7F9FB;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .about_page_mission_section{
        height: 35rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .about_page_middle_section > img{
        width: 40%;
        height: auto;
        object-fit: contain;
        object-position: center;
    }

    .about_page_middle_section > p{
        color: #3B404E;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        max-width: 35rem;
        width: 40%;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .about_page_mission_section{
        height: 30vw;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .about_page_mission_item{
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 25rem;
    }

    .about_page_mission_item > h2{
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
        margin: 0 0 2rem;
    }

    .about_page_mission_item > p{
        color: #3B404E;
        font-family: Gibson-light, sans-serif;
        margin: 0;
        text-align: center;
        max-width: 35rem;
        width: 100%;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    h2.about_page_associates_title{
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        font-weight: bold;
        letter-spacing: 0.2rem;
        width: 100%;
        margin: 0 0 2rem;
    }

    .about_page_associate_container{ 
        max-width: 100vw;       
        margin: 5rem auto;
        overflow: hidden;
        position: relative;
    }

    img.about_page_associate_item{
        position: absolute;
        height: 100%;
        width: auto;
        object-fit: contain;
    }



    /*----------------------Setup account section-----------------------*/
    .about_page_setup_account_section {
        height: 20rem;
        width: 100%;
        background: #F9FAFB;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 4rem 0 0;
    }

    .about_page_setup_account_section > h2{
        text-align: start;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        letter-spacing: 0.1rem;
        margin: 0;
        width: 25rem;
        text-decoration: underline;
    }

    .about_page_setup_account_section > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0 1rem;
        border: none;
        outline: none;
        width: 15rem;
        height: auto;
        transition: background-color 0.3s linear;
    }

    .about_page_setup_account_section > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .about_page_setup_account_section > input:focus{
        background-color: #E2E2E2;
    }

    .about_page_setup_account_section > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        text-align: center;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        cursor: pointer;
    }

    .about_page_setup_account_section > .about_page_progress_container {
        width: 7rem;
        height: 2.25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}