@media (max-width: 768px){
    .view_blog_page{
        --horizontal-blog-padding: 2rem;
        width: 100%;
        height: auto;
        min-height: calc(100vh - var(--header-height));
        margin: var(--header-height) 0 0;
        box-sizing: border-box;
        padding: 1.5rem 2rem 10rem;
        display: flex;
        flex-direction: column;
    }

    .view_blog_page > img.view_blog_page_contain {
        width: 100%;
        height: 20rem;
        max-height: calc((100vw - (var(--horizontal-blog-padding)*2))*0.75);
        margin: 1rem 0 3rem;
        object-fit: contain;
        object-position: center;
    }

    .view_blog_page > img.view_blog_page_cover {
        width: 100%;
        height: 20rem;
        max-height: calc((100vw - (var(--horizontal-blog-padding)*2))*0.75);
        margin: 1rem 0 3rem;
        object-fit: cover;
        object-position: center;
    }

    .view_blog_page > h1{
        text-align: center;
        font-size: 2rem;
        font-family: Gibson, sans-serif;
        color: #1E2432;
        margin: 3rem 0;
        font-weight: normal;
        text-decoration: underline;
        line-height: 1.3;
    }

    .view_blog_page > h2{
        text-align: start;
        font-size: 1.8rem;
        font-family: Gibson, sans-serif;
        color: #1E2432;
        margin: 3rem 1rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }

    .view_blog_page > p{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        margin: 0 1rem 2rem;
        font-weight: normal;    
        line-height: 1.4;
    }

    .view_blog_page > ol, .view_blog_page > ul{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        margin: 0 1rem 2rem;
        font-weight: normal;    
        line-height: 1.4;
    }
}

@media (min-width: 769px){
    .view_blog_page{
        width: 100%;
        height: auto;
        min-height: calc(100vh - var(--header-height));
        margin: var(--header-height) 0 0;
        box-sizing: border-box;
        padding: 1.5rem 5rem 10rem;
        display: flex;
        flex-direction: column;
    }

    .view_blog_page > img.view_blog_page_contain {
        width: 100%;
        height: 25rem;
        margin: 1rem 0 3rem;
        object-fit: contain;
        object-position: center;
    }

    .view_blog_page > img.view_blog_page_cover {
        width: 100%;
        height: 25rem;
        margin: 1rem 0 3rem;
        object-fit: cover;
        object-position: center;
    }

    .view_blog_page > h1{
        text-align: center;
        font-size: 2.6rem;
        font-family: Gibson, sans-serif;
        color: #1E2432;
        margin: 3rem 3rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }

    .view_blog_page > h2{
        text-align: start;
        font-size: 2.3rem;
        font-family: Gibson, sans-serif;
        color: #1E2432;
        margin: 3rem 3rem;
        font-weight: normal;
        letter-spacing: 0.05rem;
    }

    .view_blog_page > p{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        margin: 0 3rem 1.5rem;
        font-weight: normal;    
        line-height: 1.4;
    }

    .view_blog_page > ol, .view_blog_page > ul{
        text-align: start;
        font-size: 1rem;
        font-family: Gibson-light, sans-serif;
        color: #1E2432;
        margin: 0 3rem 1.5rem;
        font-weight: normal;    
        line-height: 1.4;
    }
}