@media (max-width: 768px){
    .process_page{
        width: 100%;
        height: auto;
        position: relative;
        padding: var(--header-height) 0 0;
        box-sizing: border-box;
    }

    .process_page_top_row{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .process_page_top_row > h2{
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 10rem 0 3rem;
        font-weight: bold;
        letter-spacing: 0.2rem;
        max-width: 20rem;
    }

    .process_page_top_row > h2 > span{
        color: #004E89;
    }


    .process_page_top_row > img{
        width: 80%;
        height: auto;
        margin: 0 auto 5rem;
    }

    /*-------------------------- Process page items section -------------------------*/

    .process_page_item_blue, .process_page_item_red{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 3.75rem 0;
    }

    .process_page_item_blue > .process_page_item_imagebox{
        background-color: #8AC0DE;
    }

    .process_page_item_blue > .process_page_item_contentbox{
        background-color: #004E89;
    }

    .process_page_item_red > .process_page_item_imagebox{
        background-color: #FEB68E;
    }

    .process_page_item_red > .process_page_item_contentbox{
        background-color: #BC4830;
    }

    .process_page_item_imagebox{
        width: 100%;
        height: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .process_page_item_imagebox > img{
        width: auto;
        max-width: 100%;
        height: 90%;
        object-position: center;
        object-fit: contain;
    }

    .process_page_item_contentbox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 3rem 1rem;
        box-sizing: border-box;
        justify-content: flex-end;
        align-items: center;
    }

    .process_page_item_contentbox > p.process_page_item_numbered-title{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 0 0 auto;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .process_page_item_contentbox > h2{
        text-align: center;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        margin: 5rem 0 1rem;
        font-weight: bold;
        letter-spacing: 0.2rem;
        line-height: 1.4;
        width: 100%;
    }

    .process_page_item_contentbox > p.process_page_item_text{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: center;
        margin: 0 0 5rem;
        font-size: 1rem;
        letter-spacing: 1px;
        font-weight: bold;
        line-height: 1.5;
    }


    /*----------------------Setup account section-----------------------*/

    .process_page_setup_account_section {
        height: 15rem;
        width: 100%;
        background: #F9FAFB;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .process_page_setup_account_section > h2{
        text-align: center;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        letter-spacing: 0.1rem;
        margin: 0;
        width: 25rem;
    }

    .process_page_setup_account_section > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 2rem 1rem 0;
        border: none;
        outline: none;
        width: 15rem;
        height: auto;
        transition: background-color 0.3s linear;
    }

    .process_page_setup_account_section > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .process_page_setup_account_section > input:focus{
        background-color: #E2E2E2;
    }

    .process_page_setup_account_section > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        cursor: pointer;
        margin: 1rem 0 0;
    }

    .process_page_setup_account_section > .process_page_progress_container {
        width: 7rem;
        height: 2.25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem 0 0;
    }

}

@media (min-width: 769px){
    .process_page{
        --min-allowed-width: 1200px;
        --max-allowed-width: 1280px;
        width: 100%;
        min-width: var(--min-allowed-width);
        height: auto;
        position: relative;
        padding: var(--header-height) 0 0;
        box-sizing: border-box;
    }

    .process_page_top_row{
        height: calc(100vh - var(--header-height));
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }

    .process_page_top_row > h2{
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        margin: 0.25rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        max-width: 20rem;
    }

    .process_page_top_row > h2 > span{
        color: #004E89;
    }


    .process_page_top_row > img{
        height: 75%;
        width: auto;
        object-fit: contain;
    }

    /*-------------------------- Process page items section -------------------------*/

    .process_page_item_blue, .process_page_item_red{
        width: 100%;
        height: 60vw;
        max-height: calc(0.6* var(--max-allowed-width));
        min-height: calc(0.6* var(--min-allowed-width));
        display: flex;
        flex-direction: row;
        margin: 3.75rem auto;
    }

    .process_page_item_blue > .process_page_item_imagebox{
        background-color: #8AC0DE;
    }

    .process_page_item_blue > .process_page_item_contentbox{
        background-color: #004E89;
    }

    .process_page_item_red > .process_page_item_imagebox{
        background-color: #FEB68E;
    }

    .process_page_item_red > .process_page_item_contentbox{
        background-color: #BC4830;
    }

    .process_page_item_imagebox{
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .process_page_item_imagebox > img{
        width: auto;
        height: 90%;
        object-position: center;
        object-fit: contain;
    }

    .process_page_item_contentbox {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 3rem 4rem;
        box-sizing: border-box;
        justify-content: flex-start;
    }

    .process_page_item_contentbox > p.process_page_item_numbered-title{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 0 0 auto;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }

    .process_page_item_contentbox > h2{
        text-align: start;
        font-size: 3rem;
        font-family: Gibson-light, sans-serif;
        color: white;
        margin: 2rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        line-height: 1.4;
        width: 100%;
    }

    .process_page_item_contentbox > p.process_page_item_text{
        color: white;
        font-family: Gibson-light, sans-serif;
        text-align: start;
        margin: 2rem 0;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
        line-height: 1.5;
    }


    /*----------------------Setup account section-----------------------*/

    .process_page_setup_account_section {
        height: 20rem;
        width: 100%;
        background: #F9FAFB;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 4rem 0 0;
    }

    .process_page_setup_account_section > h2{
        text-align: start;
        font-size: 2rem;
        font-family: Gibson-light, sans-serif;
        color: black;
        letter-spacing: 0.1rem;
        margin: 0;
        width: 25rem;
        text-decoration: underline;
    }

    .process_page_setup_account_section > input{
        font-family: Gibson-light, sans-serif;
        font-size: 0.9rem;
        color: black;
        padding: 0.75rem 1rem;
        border-radius: 0.75rem;
        background-color: #F1F1F1;
        margin: 0 1rem;
        border: none;
        outline: none;
        width: 15rem;
        height: auto;
        transition: background-color 0.3s linear;
    }

    .process_page_setup_account_section > input::placeholder{
        font-size: 0.8rem;
        color: #687086;
    }

    .process_page_setup_account_section > input:focus{
        background-color: #E2E2E2;
    }

    .process_page_setup_account_section > button {
        font-family: Josefinsans, sans-serif;
        font-size: 0.75rem;
        color: white;
        padding: 0.75rem 0;
        width: 7rem;
        text-align: center;
        border-radius: 0.75rem;
        background-color: #1E2432;
        outline: none;
        border: none;
        cursor: pointer;
    }

    .process_page_setup_account_section > .process_page_progress_container {
        width: 7rem;
        height: 2.25rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

