.home_achievements{
    width: 100%;
    height: 40vw;
    min-height: calc(var(--min-allowed-width)/2.5);
    max-height: calc(var(--max-allowed-width)/2.5);
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #024E85;
    overflow: hidden;
}

svg.home_achievements_background{
    position: absolute;
    width: 80vw;
    min-width: calc(var(--min-allowed-width)*0.8);
    max-width: calc(var(--max-allowed-width)*0.8);
    height: 80vw;
    min-height: calc(var(--min-allowed-width)*0.8);
    max-height: calc(var(--max-allowed-width)*0.8);
    z-index: 0;
    top: -80%;
    left: 60%;
}

svg.home_achievements_background > circle{
    fill: #8AC0DE;
    cx: 50%;
    cy: 50%;
    r: 50%
}

.home_achievements_stats_box{
    width: 35%;
    height: 100%;
    background-color: #F9FAFB;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 50px;
    box-sizing: border-box;
    position: absolute;
    z-index: 0;
}

.home_achievements_stats_item{
    width: 100%;
    height: auto;
}

.home_achievements_stats_item > p.home_achievements_stats_item_value{
    color: black;
    font-family: Gibson-light, sans-serif;
    text-align: start;
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    width: 100%;
}

.home_achievements_stats_item > p.home_achievements_stats_item_label{
    color: #687086;
    font-family: Gibson-light, sans-serif;
    text-align: start;
    margin: 20px 0 0;
    font-size: 20px;
    font-weight: normal;
    width: 100%;
}

.home_achievements_buttons{
    --button-diameter: 50px;
    margin-left: calc(35% - calc(var(--button-diameter)/2));
    display: flex;
    flex-direction: row;
    height: 25%;
    align-items: center;
    width: auto;
}

.home_achievements_buttons_left{
    width: var(--button-diameter);
    height: var(--button-diameter);
    border-radius: calc(var(--button-diameter));
    cursor: pointer;
    background: white url("../../Assets/round-transparent-bg-arrow.png") no-repeat center;
    background-size: contain;
    transform: rotate(-90deg);
    margin-right: 20px;
    border: none;
    outline: none;
}

.home_achievements_buttons_right{
    width: var(--button-diameter);
    height: var(--button-diameter);
    border-radius: calc(var(--button-diameter));
    cursor: pointer;
    background: white url("../../Assets/round-transparent-bg-arrow.png") no-repeat center;
    background-size: contain;
    transform: rotate(90deg);
    border: none;
    outline: none;
}

.home_achievements_card_section{
    height: 50%;
    width: 100%;
    z-index: 1;
    position: relative;
}

.home_achievements_card{
    width: 50%;
    height: 100%;
    min-height: 280px;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 50px 30px;
    box-sizing: border-box;
    z-index: 2;
    box-shadow: -10px 0 20px #00000015;
    position: absolute;
}

.home_achievements_card_extra{
    width: 33%;
    height: 100%;
    border-right: solid 1px #DAE2E8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    box-sizing: border-box;
}

.home_achievements_card_extra > img{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    object-fit: cover;
    object-position: center;
}

.home_achievements_card_extra > p.home_achievements_card_name{
    color: black;
    font-family: Gibson-light, sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    width: 100%;
    margin: 15px 0 0;
}

.home_achievements_card_extra > p.home_achievements_card_title{
    color: #687086;
    font-family: Gibson-light, sans-serif;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    margin: 15px 0 0;
    width: 100%;
}

.home_achievements_card_extra > p.home_achievements_card_org{
    font-family: Gibson-light, sans-serif;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    width: 100%;
    color: #004E89;
    margin: 15px 0 0;
}

.home_achievements_card > p.home_achievements_card_text{
    color: #687086;
    font-family: Gibson-light, sans-serif;
    text-align: start;
    margin: 0 0 0 20px;
    line-height: 1.2;
    font-size: 20px;
    font-weight: normal;
    width: 100%;
}